<template>
    <div class="container">
        <nav class="navbar navbar-expand-sm" v-bind:class=" { 'navbarOpen': show }" style="height: 81px;">
            <div class="container-fluid">
                <router-link class="navbar-brand" to="/">
                    <img :src='company.logo' alt='Logo'>
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                    aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()">
                    <ic-menu-hamburguesa icon-color='#000000'/>
                </button>
                <div class="collapse navbar-collapse bg-white " style="z-index: 4">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link txt-menu-item active" aria-current="page" to="/"  v-if='!isEditing'>{{translate.body.menu[0]}}</router-link>
                            <label class="nav-link txt-menu-item active" v-else v-on:dblclick="showEdit = true, indexEdit = 0">{{translate.body.menu[0]}}</label> 
                        </li>
                        <li v-if="!show">
                            <div class="divider-menu"></div>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link txt-menu-item active" aria-current="page" to="/about" v-if='!isEditing'>{{translate.body.menu[1]}}</router-link>
                            <label class="nav-link txt-menu-item active" v-else v-on:dblclick="showEdit = true, indexEdit = 1">{{translate.body.menu[1]}}</label> 
                        </li>
                        <li v-if="!show">
                            <div class="divider-menu"></div>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link txt-menu-item active" aria-current="page" to="/parkings" v-if='!isEditing'>{{translate.body.menu[2]}}</router-link>
                            <label class="nav-link txt-menu-item active" v-else v-on:dblclick="showEdit = true, indexEdit = 2">{{translate.body.menu[2]}}</label> 
                        </li>
                        <li v-if="!show">
                            <div class="divider-menu"></div>
                        </li>
                        <li class="nav-item" v-show="!currentAdmin">
                            <router-link class="nav-link txt-menu-item active" aria-current="page" to="/login" >Ingresar</router-link>
                        </li>
                        <li class="nav-item" v-show="currentAdmin">
                            <label class="nav-link txt-menu-item" @click="loginOff">Salir</label> 
                        </li>
                        <li class="nav-item" v-show="currentAdmin">
                            <b-form-checkbox @change="onChangeEdit" v-model="currentEdit" switch>
                                Editar
                            </b-form-checkbox>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <div class="collapse navbar-collapse bg-white full-menu" v-bind:class="{ 'show': show }" style="z-index: 4">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item bg-black" style="height: 80px;">
                &nbsp;
            </li>
            <li class="nav-item ms-4 mt-4">
                <router-link class="nav-link txt-menu-item active" aria-current="page" to="/" 
                    style="margin: 0px !important; padding: 0px !important;">
                    <div class="row">
                        <div class="col-1">
                            <ic-calendar icon-color="#fe3a00" height="40" width="40"/>
                        </div>
                        <div class="col mt-2" style="text-transform:none;"> 
                            Realizar una reserva
                        </div>
                        <div class="col-2">
                            <ic-flecha-pequena-derecha icon-color="#fe3a00" transform='rotate(90)'/>
                        </div>
                    </div>
                </router-link>
            </li>
            <li class="nav-item ms-4">
                <router-link class="nav-link txt-menu-item active" aria-current="page" to="/about" 
                    style="margin: 0px !important;  padding: 0px !important;">
                    <div class="row">
                        <div class="col-1">
                            <ic-informacion icon-color="#fe3a00" height="40" width="40"/>
                        </div>
                        <div class="col mt-2" style="text-transform:none;"> 
                            Atención al cliente
                        </div>
                        <div class="col-2">
                            <ic-flecha-pequena-derecha icon-color="#fe3a00" transform='rotate(90)'/>
                        </div>
                    </div>
                </router-link>
            </li>
            <li class="nav-item ms-4 mb-4">
                <router-link class="nav-link txt-menu-item active" aria-current="page" to="/parkings/information" 
                    style="margin: 0px !important;  padding: 0px !important;">
                    <div class="row">
                        <div class="col-1">
                            <ic-parking icon-color="#fe3a00" height="40" width="40"/>
                        </div>
                        <div class="col mt-2" style="text-transform:none;"> 
                            Nuestros Parking
                        </div>
                        <div class="col-2">
                            <ic-flecha-pequena-derecha icon-color="#fe3a00" transform='rotate(90)'/>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.body.menu[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import IcParking from './Icons/IcParking.vue'
import IconPerson from './Icons/IconPerson.vue'
import IcCalendar from './Icons/IcCalendar.vue'
import ModalTranslation from './ModalTranslation.vue'
import IcInformacion from './Icons/IcInformacion.vue'
import IcMenuHamburguesa from './Icons/IcMenuHamburguesa.vue'
import IcFlechaPequenaDerecha from './Icons/IcFlechaPequenaDerecha.vue'

export default {
    name: 'NavBar',
    components: {
        IcParking,
        IconPerson,
        IcCalendar,
        IcInformacion,
        ModalTranslation,
        IcMenuHamburguesa,
        IcFlechaPequenaDerecha,
    },
    data() {
        return {
            show: false,
            indexEdit: 0,
            showEdit: false,
            currentEdit: false,
        }
    },
    methods: {
        loginOff() {
            store.dispatch('setToken', null);
            store.dispatch('setEdit', false);
            store.dispatch('setCurrentAdmin', false);
        },
        onChangeEdit() {
            store.dispatch('setEdit', this.currentEdit);
        },
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
        },
        toggleNavbar() {
            this.show = !this.show
        },
        getCompanies(code) {
            axios.get(`api/${this.modules[code]}`)
                .then(response => {
                    this.items = response.data;
                }).catch(error => {
                    this.$toast.error(error);
                });
        },
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing', "token", "currentAdmin"]),
    },
    mounted() {
        console.log(this.currentEdit);
    }
}
</script>

<style>
.full-menu {
    position: absolute;
    width: 100%;
}

.logo {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.divider-menu {
  width: 1px;
  height: 25px;
  margin: 10px 14px 1px 13px;
  border: solid 1px #fe3a00;
}

.txt-menu-item {
  margin: 7px 13px 5px 0;
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-transform: uppercase;
}

nav a {
    font-weight: bold;
    color: Black;
}

nav a.router-link-exact-active {
    color: #fe3a00;
}
</style>
