<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M14.8910866,13.4768734 C16.5610148,11.1336691 16.344866,7.85958481 14.2426405,5.75735927 C11.8994946,3.41421342 8.10050499,3.41421366 5.75735939,5.75735927 C3.41421354,8.10050511 3.41421354,11.8994946 5.75735939,14.2426405 C7.85958493,16.344866 11.1336689,16.561015 13.4768734,14.8910866 L19.1923876,20.6066017 L20.6066017,19.1923876 L14.8910866,13.4768734 C16.004372,11.9147372 16.004372,11.9147372 14.8910866,13.4768734 Z M12.8284273,7.17157269 C14.3905244,8.73366976 14.3905244,11.2663302 12.8284273,12.8284273 C11.2663301,14.3905245 8.73367023,14.3905244 7.17157316,12.8284273 C5.60947597,11.2663301 5.60947597,8.73366988 7.17157316,7.17157269 C8.73367023,5.60947561 11.2663301,5.60947549 12.8284273,7.17157269 C13.8698254,8.21297073 13.8698254,8.21297073 12.8284273,7.17157269 Z" id="edit-/-search"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_lupa'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
