<template>
    <div class="header-panel">
        <div class="container"> 
            <div class="row">
                <div class="col-12" style="margin-top: 30px; margin-bottom: 30px">
                    <router-link style="text-decoration: none !important" to="/parkings" v-if="!isEditing">
                        <ic-circulo-flecha-izquierda icon-color="#5d5d5d"/> 
                        <span class="txt-return" v-on:dblclick="setEdit(0)">
                            {{translate.parkings_info[0]}}
                        </span>
                    </router-link>
                    <span v-else v-on:dblclick="setEdit(0)"> 
                        <ic-circulo-flecha-izquierda icon-color="#5d5d5d"/> 
                        <span class="txt-return">
                            {{translate.parkings_info[0]}}
                        </span>
                    </span> 
                </div>
                <div class="col-12 text-center pt-4 mb-5 showMovil"  v-on:dblclick="setEditTextarea(1)" v-html="translate.parkings_info[1]" />
                <div class="col-12 col-sm-12 col-md-9"> 
                    <agile 
                        :arrows="false" 
                        :speed="1000" 
                        :fade="false" 
                        :dots="false"
                        :autoplay="true"
                        :centerMode="true"
                        :navButtons="false"
                    >
                        <div class="slide text-center">
                            <img src="../../assets/images/img_mapa_parking_barajas_1@2x.png" style="width: 80%;">
                        </div>
                        <div class="slide text-center">
                            <img src="../../assets/images/img_mapa_parking_barajas_2@2x.png" style="width: 80%;">
                        </div>
                    </agile>
                </div>
                <div class="col-12 col-sm-12 col-md-3"> 
                    <div class="row">
                        <div class="col-12 showFull">
                            <label class="header-info" v-on:dblclick="setEdit(2)">
                                {{translate.parkings_info[2]}}
                            </label>
                        </div>
                        <div class="col-12 showFull" v-html="translate.parkings_info[3]" v-on:dblclick="setEditTextarea(3)"/>
                        <div class="col-12 ps-4">
                            <label class="header-info" style="margin: 20px 0px" v-on:dblclick="setEdit(4)">
                                {{translate.parkings_info[4]}}
                            </label>
                        </div>
                        <div class="col-12 ps-4" v-html="translate.parkings_info[5]" v-on:dblclick="setEditTextarea(5)" />
                        <div class="col-12 ps-4">
                            <label class="header-info" style="margin: 20px 0px" v-on:dblclick="setEdit(6)">
                                {{translate.parkings_info[6]}}
                            </label>
                        </div>
                        <div class="col-12 ps-4" v-html="translate.parkings_info[7]" v-on:dblclick="setEditTextarea(7)" />
                        <div class="offset-1 col-10 p-2 pt-4">
                            <ButtonOrange :label="translate.parkings_info[8]" v-if="!isEditing"/>
                            <div class="btn-booking" v-else v-on:dblclick="setEdit(8)">{{translate.parkings_info[8]}}</div>
                        </div>
                        <div class="offset-1 col-10 p-2 mb-4">
                            <ButtonTransparent :label="translate.parkings_info[9]" v-if="!isEditing" />
                            <div class="button-transparent" v-else v-on:dblclick="setEdit(9)">{{translate.parkings_info[9]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.parkings_info[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.parkings_info[indexEdit]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import {mapState} from 'vuex'
import store from '../../store'
import { VueAgile } from 'vue-agile'
import ButtonOrange from '../ButtonOrange.vue'
import ButtonTransparent from '../ButtonTransparent.vue'
import IcCirculoFlechaIzquierda from '../Icons/IcCirculoFlechaIzquierda.vue'

export default {
    name: 'ParkingInfoHeader',
    computed: {
        ...mapState(['translate', 'isEditing'])
    },
    data() {
        return {
            items: [],
            findFaq: null,
            indexEdit: null,
            showEdit: false,
            showEditTexarea: false,
        }
    }, 
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        setEdit(index) {
            const self = this;
            if (self.isEditing) { 
                self.showEdit = true; 
                self.indexEdit = index;
            }
        },
        setEditTextarea(index) {
            const self = this;
            if (self.isEditing) { 
                self.showEditTexarea = true; 
                self.indexEdit = index;
            }
        },
    },
    components: {
        ButtonOrange,
        agile: VueAgile,
        ButtonTransparent,
        IcCirculoFlechaIzquierda,
    }
}
</script>

<style scoped>
.button-transparent {
    width: 100%;
    padding: 8px;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: rgb(0,0,0, .2);
}

.btn-booking {
    width: 100%;
    padding: 8px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: #fe3a00;
}

.heater-title-desc > span {
    color: #fe3a00;
}

.heater-title-desc {
    font-family: 'Raleway';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.header-body {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-body > span {
    font-weight: 900;
}

.header-title {
    color: #000;
    font-size: 36px;
    font-weight: 800;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-title > span {
    color: #fe3a00;
}

.txt-return, .header-info {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-info {
    font-weight: 800;
}

.header-panel {
    background-color: #ededed;
}
</style>
