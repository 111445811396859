<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M9,12 L9,14 L7,14 L7,12 L9,12 Z M9,16 L9,18 L7,18 L7,16 L9,16 Z M11,12 L11,14 L13,14 L13,12 L11,12 Z M13,16 L13,18 L11,18 L11,16 L13,16 Z M15,12 L15,14 L17,14 L17,12 L15,12 Z M17,16 L17,18 L15,18 L15,16 L17,16 Z M19,22 L5,22 C3.89699984,22 3,21.1030006 3,20 L3,6 C3,4.89699984 3.89699984,4 5,4 L7,4 L7,2 L9,2 L9,4 L15,4 L15,2 L17,2 L17,4 L19,4 C20.1030006,4 21,4.89699984 21,6 L21,20 C21,21.1030006 20.1030006,22 19,22 L19,22 Z M19.0001431,8 L19,6 L5,6 L5,8 L19.0001431,8 L19.0001431,8 Z M19.0002861,10 L19.0009995,20 L5,20 L5,10 L19.0002861,10 L19.0002861,10 Z" id="calendar-/-calendar" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_bola_moto'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
