<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M9,2 L9,4 L15,4 L15,2 L17,2 L17,4 L19,4 C20.1030006,4 21,4.89699984 21,6 L21,20 C21,21.1030006 20.1030006,22 19,22 L5,22 C3.89699984,22 3,21.1030006 3,20 L3,6 C3,4.89699984 3.89699984,4 5,4 L7,4 L7,2 L9,2 Z M19.0002861,10 L5,10 L5,20 L19.0009995,20 L19.0002861,10 Z M14.5017514,12.3466638 L14.5889722,12.4243701 L16.210807,14.0455132 C16.271595,14.08415 16.329122,14.1299932 16.3821715,14.1830427 C16.5815158,14.382387 16.6791042,14.6449543 16.6749367,14.9061998 C16.6797691,15.1680784 16.5821961,15.4317584 16.3821715,15.631783 C16.3290977,15.6848568 16.2715424,15.7307176 16.2107236,15.7693655 L14.5972563,17.3821715 C14.206732,17.7726958 13.573567,17.7726958 13.1830427,17.3821715 C12.820413,17.0195418 12.7945109,16.4476964 13.1053364,16.0551788 L13.154,15.9994769 L9,16 C8.44771525,16 8,15.5522847 8,15 C8,14.4871642 8.38604019,14.0644928 8.88337887,14.0067277 L9,14 L13.335,13.9994769 L13.1747586,13.8385837 C12.7842344,13.4480594 12.7842344,12.8148944 13.1747586,12.4243701 C13.5373883,12.0617404 14.1092337,12.0358383 14.5017514,12.3466638 Z M19,6 L5,6 L5,8 L19.0001431,8 L19,6 Z" id="Combined-Shape"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_calendario_flecha_derecha'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
