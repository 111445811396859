import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import HomeView from '../views/HomeView.vue'
import ResumeView from '../views/ResumeView.vue'
import SupportView from '../views/SupportView.vue'
import ParkingView from '../views/ParkingView.vue'
import ServicesView from '../views/ServicesView.vue'
import ConfirmationView from '../views/ConfirmationView.vue'
import InsurancesView from '../views/InsurancesViews.vue'
import ParkingInfoView from '../views/ParkingInfoView.vue'
import ClientRegisterRegister from '../views/Client/Register/RegisterView.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: SupportView
    },
    {
        path: '/parkings',
        name: 'parkings',
        component: ParkingView
    },
    {
        path: '/parkings/information',
        name: 'parkingsInfo',
        component: ParkingInfoView
    },
    {
        path: '/clients/register',
        name: 'clientRegisterRegister',
        component: ClientRegisterRegister
    },
    {
        path: '/services',
        name: 'services',
        component: ServicesView
    },
    {
        path: '/insurances',
        name: 'insurances',
        component: InsurancesView
    },
    {
        path: '/confirmation',
        name: 'confirmation',
        component: ConfirmationView
    },
    {
        path: '/resume/:id',
        name: 'resume',
        component: ResumeView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
