<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :viewBox="`0 0 ${width} ${height}`" 
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M13.4497471,7 L14.8639603,8.41421413 L11.3284273,11.9497471 L14.8639622,15.485281 L13.4497471,16.8994951 L8.5,11.9497471 L13.4497471,7 Z M22,12 C22,17.5228472 17.5228472,22 12,22 C6.47715235,22 2,17.5228472 2,12 C2,6.47715235 6.47715235,2 12,2 C17.5228472,2 22,6.47715235 22,12 C22,15.6818981 22,15.6818981 22,12 Z M20,12 C20,16.4182777 16.4182777,20 12,20 C7.58172178,20 4,16.4182777 4,12 C4,7.58172178 7.58172178,4 12,4 C16.4182777,4 20,7.58172178 20,12 C20,14.9455185 20,14.9455185 20,12 Z" id="arrow-/-circle_chevron_left"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_circulo_flecha_izquierda'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
