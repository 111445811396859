<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :viewBox="`0 0 ${width} ${height}`" 
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor"  d="M18,1 C19.6568542,1 21,2.34314575 21,4 L21,4 L21,18 C20.9947486,19.2667854 20.1943509,20.3937454 19,20.816 L19,20.816 L19,21.5 C19,22.3284271 18.3284271,23 17.5,23 C16.6715729,23 16,22.3284271 16,21.5 L16,21.5 L16,21 L8,21 L8,21.5 C8,22.3284271 7.32842712,23 6.5,23 C5.67157288,23 5,22.3284271 5,21.5 L5,21.5 L5,20.816 C3.80564913,20.3937454 3.00525139,19.2667854 3,18 L3,18 L3,4 C3,2.34314575 4.34314575,1 6,1 L6,1 Z M19,15 L5,15 L5,18 C5,18.5522847 5.44771525,19 6,19 L6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,18 L19,15 Z M7,16 C7.55228475,16 8,16.4477153 8,17 C8,17.5522847 7.55228475,18 7,18 C6.44771525,18 6,17.5522847 6,17 C6,16.4477153 6.44771525,16 7,16 Z M17,16 C17.5522847,16 18,16.4477153 18,17 C18,17.5522847 17.5522847,18 17,18 C16.4477153,18 16,17.5522847 16,17 C16,16.4477153 16.4477153,16 17,16 Z M19,7 L5,7 L5,13 L19,13 L19,7 Z M18,3 L6,3 C5.44771525,3 5,3.44771525 5,4 L5,4 L5,5 L19,5 L19,4 C19,3.44771525 18.5522847,3 18,3 L18,3 Z" id="Combined-Shape" fill-rule="nonzero"></path>
        </g>
</svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_autobus'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
