<template>
    <button 
        type="button" 
        @click="onClick"
        class="button-transparent"
    >
        {{label}}
    </button>
</template>

<script>

export default {
    name: 'ButtonOrange',
    props: {
        label: {
            type: String,
            default: 'Boton'
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick");
        },
    },
}
</script>

<style scoped>
.button-transparent {
    width: 100%;
    padding: 8px;
    color: black;
    font-weight: bold;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: rgb(0,0,0, .2);
}
</style>
