<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M7.85699987,14.0699997 L14.1169996,17.6469994 C14.0479996,17.9219994 14,18.204 14,18.5 C14,20.4299999 15.5700001,22 17.5,22 C19.4299999,22 21,20.4299999 21,18.5 C21,16.5700001 19.4299999,15 17.5,15 C16.59,15 15.7669997,15.3580003 15.1429996,15.9300003 L8.88300037,12.3529997 C8.94600037,12.1059997 8.98600018,11.851 8.99100018,11.585 L15.1420002,8.06999969 C15.7670002,8.64199972 16.59,9 17.5,9 C19.4299999,9 21,7.42999995 21,5.5 C21,3.57000005 19.4299999,2 17.5,2 C15.5700001,2 14,3.57000005 14,5.5 C14,5.796 14.0479996,6.07800016 14.1169996,6.35300016 L8.43299961,9.60200024 C7.80799961,8.64000022 6.72899997,8 5.5,8 C3.57000005,8 2,9.57000005 2,11.5 C2,13.4299999 3.57000005,15 5.5,15 C6.41000003,15 7.23299986,14.6419997 7.85699987,14.0699997 Z M19,18.5 C19,19.327 18.327,20 17.5,20 C16.673,20 16,19.327 16,18.5 C16,17.673 16.673,17 17.5,17 C18.327,17 19,17.673 19,18.5 Z M19,5.5 C19,6.32700002 18.327,7 17.5,7 C16.673,7 16,6.32700002 16,5.5 C16,4.67299998 16.673,4 17.5,4 C18.327,4 19,4.67299998 19,5.5 Z M7,11.5 C7,12.327 6.32700002,13 5.5,13 C4.67299998,13 4,12.327 4,11.5 C4,10.673 4.67299998,10 5.5,10 C6.32700002,10 7,10.673 7,11.5 Z" id="basic-/-share_outline" fill="#000000"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_compartir'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
