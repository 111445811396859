<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-4">
                    <div :class="`row ${isDefaultClass}`">
                        <div class="col-12">
                            <div class="txt-discount">-{{service.discount}}%</div>
                        </div>
                        <div class="col-12 txt-discount-description" v-if="service.default">
                            ¡Solo hoy!
                        </div>
                    </div>
                </div>
                <div class="offset-7 col-2">
                    <ic-circulo-flecha-derecha icon-color="#5d5d5d"/>
                </div>
            </div>
            <div class="row" :style="service.default ? 'margin-top: -20px' : 'margin-top: 0px'">
                <div class="col-12 text-center">
                    <div class="row">
                        <div class="col">
                            <span class="icon-more" v-for="(icon, index) in service.icons" :key="index">
                                <span  v-if="index > 0">+</span>
                                <img :src='icon.icon' alt='Icono' style="margin:10px">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px">
                <div class="col-12 text-center" style="min-height: 58px">
                    <span class="card-title">{{service.title}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center"  style="min-height: 110px">
                    <span v-html="service.description" class="txt-body"/>
                </div>
                <div class="col-12">
                    <hr style="border: solid 1px #fe3a00;"/>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <span class="total-transfers">Total traslados</span>
                        </div>
                        <div class="col-6 text-right">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <div class="row">
                                        <div class="col-9 text-right">
                                            <span class="text-price-promotion">
                                                {{calculateTotalPromotion}}€ 
                                            </span>
                                       </div>
                                        <div class="col-2 ">
                                            <div class="bg-secondary bg-opacity-10 arrow-up" @click="onChangeShowDetail">
                                                <div style="position: relative; margin-left:5; width: 26px;">
                                                    <ic-flecha-pequena-arriba icon-color="#5d5d5d" v-if="showDetail"/>
                                                    <ic-flecha-pequena-abajo icon-color="#5d5d5d" v-else />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-right" 
                                    style="text-align: right; padding-right: 50px;">
                                    <span class="text-price">{{calculateTotal}}€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 bg-secondary bg-opacity-10 box-detail" v-if="showDetail">
                    <div class="row">
                        <div class="col-8">
                            <span class="txt-description-title">
                                <span>•</span> Coste total Suttle salida
                            </span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="text-price-description-promo">
                                {{calculatePikup}}€ 
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <span class="txt-description">
                                Nº de pasajeros salida T1
                            </span>
                        </div>
                        <div class="col-2 text-right">
                            <span class="txt-description-passagers">
                                {{passagerPikup}}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <span class="txt-description">
                                Tarifa por pasajero
                            </span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="txt-description-passagers">
                                {{havePromotion ? pricePromotion : service.price}}€
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <span class="txt-description-title">
                                <span>•</span> Coste total Suttle regreso
                            </span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="text-price-description-promo">
                                {{calculateDelivery}}€ 
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <span class="txt-description">
                                Nº de pasajeros salida T1
                            </span>
                        </div>
                        <div class="col-2 text-right">
                            <span class="txt-description-passagers">
                                {{passagerDelivery}}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <span class="txt-description">
                                Tarifa por pasajero
                            </span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="txt-description-passagers">
                                {{pricePromotion*passagerDelivery}}€
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center" style="padding: 10px;">
                    <ButtonOrange 
                        :active="active"
                        label="Seleccionar"
                        @onClick="onSelectService"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center" style="padding: 10px;">
                    <ButtonTransparent 
                        label="Más informacion"
                        @onClick="onMoreInfo" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonOrange from "./ButtonOrange.vue"
import ButtonTransparent from "./ButtonTransparent.vue"
import IcFlechaPequenaAbajo from "./Icons/IcFlechaPequenaAbajo.vue"
import IcFlechaPequenaArriba from "./Icons/IcFlechaPequenaArriba.vue"

export default {
    name: 'CardService',
    components: {
        ButtonOrange,
        ButtonTransparent,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
    },
    data() {
        return {
            showDetail: false
        }
    },
    props: {
        active: {
            type: Boolean,
            default: true
        },
        service: Object,
        passagerPikup: {
            type: Number
        },
        passagerDelivery: {
            type: Number
        },
    },
    methods: {
        onChangeShowDetail() {
            this.showDetail = !this.showDetail;
        },
        onMoreInfo() {
            this.$emit("onMoreInfo");
        },
        onSelectService() {
            this.$emit("onSelectService", this.service.id);
        },
    },
    computed: {
        havePromotion() {
            return this.service.discount > 0;
        },
        havePromotionCss() {
            return this.service.discount > 0 ? 'card-price' : 'card-promotion-price';
        },
        pricePromotion() {
            var promoDiscount = (this.service.price*(this.service.discount/100)); 
            var pricePromo = (this.service.price - promoDiscount);
            return parseFloat(pricePromo).toFixed(2);
        },
        isDefaultClass() {
            return this.service.default ? 'box-discount-dafault' : 'box-discount';
        },
        calculateTotal() {
            let currentPassagerPikup = (this.passagerPikup ? parseInt(this.passagerPikup) : 0);
            let currentPassagerDelivery = (this.passagerDelivery ? parseInt(this.passagerDelivery) : 0);
            var price = (currentPassagerPikup  < 8 && currentPassagerPikup > 0) ? (this.service.price * (currentPassagerPikup + currentPassagerDelivery)) : this.service.price;
            return parseFloat(price).toFixed(2);
        },
        calculateTotalPromotion() {
            let currentPassagerPikup = (this.passagerPikup ? parseInt(this.passagerPikup) : 0);
            let currentPassagerDelivery = (this.passagerDelivery ? parseInt(this.passagerDelivery) : 0);
            var price = (currentPassagerPikup < 8 && currentPassagerPikup > 0) ? (this.pricePromotion * (currentPassagerPikup + currentPassagerDelivery)) : this.pricePromotion;
            return parseFloat(price).toFixed(2);
        },
        calculatePikup() {
            var price = 0;
            let currentPassagerPikup = (this.passagerPikup ? parseInt(this.passagerPikup) : 0);

            if (this.havePromotion) {
                price = (currentPassagerPikup  < 8 && currentPassagerPikup > 0) ? (this.pricePromotion * currentPassagerPikup) : this.pricePromotion;
            } else {
                price = (currentPassagerPikup  < 8 && currentPassagerPikup > 0) ? (this.service.price * currentPassagerPikup) : this.service.price;
            }
            return parseFloat(price).toFixed(2);
        },
        calculateDelivery() {
            var price = 0;
            let currentPassagerDelivery = (this.passagerDelivery ? parseInt(this.passagerDelivery) : 0);

            if (this.havePromotion) {
                price = (currentPassagerDelivery  < 8 && currentPassagerDelivery > 0) ? (this.pricePromotion * currentPassagerDelivery) : this.pricePromotion;
            } else {
                price = (currentPassagerDelivery  < 8 && currentPassagerDelivery > 0) ? (this.service.price * currentPassagerDelivery) : this.service.price;
            }
            return parseFloat(price).toFixed(2);
        },
    }
}
</script>

<style scoped>
.box-detail {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgb(0 0 0 / 15%);
}

.arrow-up {
    position: absolute;
    margin-left: -15px;
    height: 50px;
    width: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgb(0 0 0 / 15%);
}

.txt-description-passagers {
    color: #000; 
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    font-family: 'Raleway';
}


.text-price-description-promo {
    color: #fe3a00;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-description-title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-description {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}


.text-price {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.text-price-promotion {
    color: #fe3a00;
    font-size: 17px;
    text-align: right;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-family: 'Raleway';
}

.total-transfers {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-transform: uppercase;
}

.txt-body {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.07;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card-title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.icon-more {
    color: #c2c2c2;
    font-size: 40px;
    font-family: 'Raleway';

}

.box-discount {
    margin-top: -17px;
    margin-left: -17px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: black;
}

.box-discount-dafault {
    margin-top: -17px;
    margin-left: -17px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fe3a00;
}

.txt-discount-description {
    font-family: 'Raleway';
    font-size: 9px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.txt-discount {
    margin: 2px;
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

</style>
