<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M7,8 C7,10.757 9.24300003,13 12,13 C14.757,13 17,10.757 17,8 C17,5.24300003 14.757,3 12,3 C9.24300003,3 7,5.24300003 7,8 Z M9,8 C9,6.34599996 10.346,5 12,5 C13.654,5 15,6.34599996 15,8 C15,9.65400004 13.654,11 12,11 C10.346,11 9,9.65400004 9,8 Z M21,21 C21,17.141 17.859,14 14,14 L10,14 C6.1400001,14 3,17.141 3,21 L3,22 L5,22 L5,21 C5,18.243 7.24300003,16 10,16 L14,16 C16.757,16 19,18.243 19,21 L19,22 L21,22 L21,21 Z" id="user-/-user" fill="#000000"></path>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_persona'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
