<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :viewBox="`0 0 ${width} ${height}`" 
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M19,2 C20.7,2 22,3.3 22,5 L22,5 L22,19 C22,20.7 20.7,22 19,22 L19,22 L5,22 C3.3,22 2,20.7 2,19 L2,19 L2,5 C2,3.3 3.3,2 5,2 L5,2 Z M19,4 L5,4 C4.4,4 4,4.4 4,5 L4,5 L4,19 C4,19.6 4.4,20 5,20 L5,20 L19,20 C19.6,20 20,19.6 20,19 L20,19 L20,5 C20,4.4 19.6,4 19,4 L19,4 Z M12.5,7 C14.4,7 16,8.6 16,10.5 C16,12.4 14.4,14 12.5,14 L12.5,14 L11,14 L11,17 L9,17 L9,7 Z M12.5,9 L11,9 L11,12 L12.5,12 C13.3,12 14,11.3 14,10.5 C14,9.7 13.3,9 12.5,9 L12.5,9 Z" id="Combined-Shape" fill-rule="nonzero"></path>
        </g>
</svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_parking'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
