import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import store from './store'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

console.log(localStorage.getItem('token'));
console.log((localStorage.getItem('currentAdmin') === 'true'));
store.dispatch('setToken', localStorage.getItem('token'));
store.dispatch('setCurrentAdmin', (localStorage.getItem('currentAdmin') === 'true'));

axios.get('/companies/valet')
    .then(response => {
        store.dispatch('setCompany', response.data.data);
    }).catch(() => {
        store.dispatch('setCompany', null);
    }).finally(() => {
        axios.get('/translates')
            .then(response => {
                store.dispatch('setTranslate', response.data.data);
            }).catch(() => {
                store.dispatch('setTranslate', null);
            }).finally(() => {
                createApp(App).use(store).use(router).use(BootstrapVue3).mount('#app')
            });
    });

