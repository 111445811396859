<template>
    <div class="footer-background d-none d-md-block pt-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-2 text-center" >
                    <logo-parking-barajas-horizontal />
                </div>
                <div class="col-5 col-sm-5 col-md-2">
                    <div class="foother-menu" style="" v-on:dblclick="setEdit(0)">
                        {{translate.body.footer.menu[0]}}
                    </div>
                </div>
                <div class="col-1">
                    <div class="foother-menu">
                        <div class="divider-menu"></div>
                    </div>
                </div>
                <div class="col-5 col-sm-5 col-md-1">
                    <div class="foother-menu" v-on:dblclick="setEdit(1)">
                        {{translate.body.footer.menu[1]}}
                    </div>
                </div>
                <div class="col-1">
                    <div class="foother-menu">
                        <div class="divider-menu"></div>
                    </div>
                </div>
                <div class="col-5 col-sm-5 col-md-2">
                    <div class="foother-menu" v-on:dblclick="setEdit(2)">
                        {{translate.body.footer.menu[2]}}
                    </div>
                </div>
                <div class="col-1">
                    <div class="foother-menu">
                        <div class="divider-menu"></div>
                    </div>
                </div>
                <div class="col-5 col-sm-5 col-md-2">
                    <div class="foother-menu" v-on:dblclick="setEdit(3)">
                        {{translate.body.footer.menu[3]}}
                    </div>
                </div>
                <div class="col-12"  style="margin-top: 30px">
                    <div class="footer-legend" v-html="translate.body.footer.description" v-on:dblclick="setTextarea" />
                </div>
            </div>
        </div>
    </div>
    <div class="footer-background  d-md-none pb-5">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center pt-5" >
                    <logo-parking-barajas-horizontal />
                </div>
                <div class="col-12  text-center pt-3 ps-2 pe-2">
                    <div class="footer-legend" v-html="translate.body.footer.description" v-on:dblclick="setTextarea" />
                </div>
                <div class="offset-4 col-4">
                    <hr style="color: #fe3a00;"/>
                </div>
                <div class="col-12 text-center p-3">
                    <div class="foother-menu" v-on:dblclick="setEdit(0)">
                        {{translate.body.footer.menu[0]}}
                    </div>
                </div>
                <div class="col-12 text-center p-3">
                    <div class="foother-menu" v-on:dblclick="setEdit(1)">
                        {{translate.body.footer.menu[1]}}
                    </div>
                </div>
                <div class="col-12 text-center p-3">
                    <div class="foother-menu" v-on:dblclick="setEdit(2)">
                        {{translate.body.footer.menu[2]}}
                    </div>
                </div>
                <div class="col-12 text-center p-3">
                    <div class="foother-menu" v-on:dblclick="setEdit(3)">
                        {{translate.body.footer.menu[3]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.body.footer.menu[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="isTextarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.body.footer.description"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import LogoParkingBarajasHorizontal from './Logos/LogoParkingBarajasHorizontal.vue'

export default {
    name: 'Footer',
    data() {
        return {
            indexEdit: 0,
            showEdit: false,
            isTextarea: false,
        }
    },
    components: {
        LogoParkingBarajasHorizontal
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
            self.isTextarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
            self.isTextarea = false;
        },
        setTextarea() {
            if (this.isEditing) { 
                this.isTextarea = true; 
            }
        },
        setEdit(index) {
            if (this.isEditing) { 
                this.showEdit = true; 
                this.indexEdit = index;
            }
        },
        getCompanies(code) {
            axios.get(`api/${this.modules[code]}`)
                .then(response => {
                    this.items = response.data;
                }).catch(error => {
                    this.$toast.error(error);
                });
        },
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing'])
    }
}
</script>

<style scoped>
.footer-legend {
    margin-bottom: 20px;
    font-family: 'Raleway';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

@media (min-width: 575px) { 
    .footer-legend {
        margin-bottom: 50px;
    }
}

.divider-menu {
    float: left;
}

.foother-menu {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
    text-transform: uppercase;
}

.footer-background {
    background-color:#000000;
}

.text-style-1 {
  font-size: 9px;
  font-weight: bold;
}

</style>
