import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
    state: {
        token:null,
        company:null,
        translate:null,
        isEditing:false,
        currentAdmin:false
    },
    getters: {

    },
    mutations: {
        setToken(state, payload) {
            state.token = payload;
        },
        setCurrentAdmin(state, payload) {
            state.currentAdmin = payload;
        },
        setEdit(state, payload) {
            state.isEditing = payload;
        },
        setCompany(state, payload) {
            state.company = payload;
        },
        setTranslate(state, payload) {
            state.translate = payload;
        },
        saveTranslate(state) {
            axios.post('/translates', state.translate)
                .then(response => {
                    console.log(response);
                }).catch((er) => {
                    console.log(er);
                });
        }
    },
    actions: {
        setToken({commit}, token) {
            localStorage.setItem('token', token);
            commit('setToken', token);
        },
        setCurrentAdmin({commit}, currentAdmin) {
            localStorage.setItem('currentAdmin', currentAdmin);
            commit('setCurrentAdmin', currentAdmin);
        },
        setEdit({commit}, isEditing) {
            commit('setEdit', isEditing);
        },
        setCompany({commit}, company) {
            localStorage.setItem('company', JSON.stringify(company));
            commit('setCompany', company);
        },
        setTranslate({commit}, translate) {
            commit('setTranslate', translate);
        },
        saveTranslate({commit}) {
            commit('saveTranslate');
        }
    },
    modules: {
    }
})
