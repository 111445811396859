<template>
    <div class="header-support">
        <div class="panel-bar">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 topMargin">
                        <ic-circulo-persona icon-color="#fe3a00"/> 
                        <span class="bar-label" v-on:dblclick="setEdit(2)">
                            {{translate.services[2]}}
                        </span>
                    </div>
                    <div class="col-12 col-md-4 topMargin">
                        <div class="row">
                            <div class="col-4 col-md-3">
                                <span class="bar-label" v-on:dblclick="setEdit(3)">
                                    {{translate.services[3]}}
                                </span>
                            </div>
                            <div class="col-8 col-md-8">
                                <select name="select" class="form-select" @change="onChangePassagerPikup">
                                    <option value="" disabled selected hidden>Seleccione</option>
                                    <option v-for="index in 8" :key="index" :value="index">{{index}}</option>
                                    <option value="9">No necesito traslado</option>
                                    <option value="10">Drivercar</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 topMargin">
                        <div class="row">
                            <div class="col-4 col-md-3">
                                <span class="bar-label" v-on:dblclick="setEdit(4)">
                                    {{translate.services[4]}}
                                </span>
                            </div>
                            <div class="col-8 col-md-8">
                                <select name="select" class="form-select" @change="onChangePassagerDelivery">
                                    <option value="" disabled selected hidden>Seleccione</option>
                                    <option v-for="index in 8" :key="index" :value="index">{{index}}</option>
                                    <option value="9">No necesito traslado</option>
                                    <option value="10">Drivercar</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.services[indexEdit]" rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import store from '../store'
import { mapState } from 'vuex'
import IcCirculoPersona from '@/components/Icons/IcCirculoPersona.vue'

export default {
    name: 'BarPassager',
    data() {
        return {
            indexEdit: null,
            showEditTexarea: false,
        }
    }, 
    computed: {
        ...mapState(['translate', 'isEditing'])
    },
    components: {
        IcCirculoPersona
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTexarea = false;
        },
        setEdit(index) {
            const self = this;
            if (self.isEditing) { 
                self.indexEdit = index; 
                self.showEditTexarea = true; 
            }
        },
        onChangePassagerPikup(event) {
            this.$emit("onChangePassagerPikup", event.target.value);
        },
        onChangePassagerDelivery(event) {
            this.$emit("onChangePassagerDelivery", event.target.value);
        },
    }
}
</script>

<style  scoped>
.topMargin {
    padding: 10px;
}

@media (min-width: 768px) {
    .topMargin {
        padding: 25px;
    }
}

.bar-label {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-transform: uppercase;
}

.header-support {
    padding: 20px 0px;
    background-color: rgba(0, 0, 0, 0.15);
}

.panel-bar {
    background-color: #fff;
}
</style>
