<template>
    <div class="card">
        <div class="card-body">
            <div class="row" v-if="havePromotion">
                <div class="col-4">
                    <div :class="`row ${isDefaultClass}`">
                        <div class="col-12">
                            <div class="txt-discount">-{{insurance.discount}}%</div>
                        </div>
                        <div class="col-12 txt-discount-description" v-if="insurance.default">
                            ¡Solo hoy!
                        </div>
                    </div>
                </div>
                <div class="offset-7 col-2">
                    <ic-circulo-flecha-derecha icon-color="#5d5d5d"/>
                </div>
            </div>
            <div class="row" :style="insurance.default ? 'margin-top: -20px' : 'margin-top: 0px'">
                <div class="col-12 text-center">
                    <div class="row">
                        <div class="col">
                            <img :src='insurance.icon' alt='Icono' style="margin:10px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px">
                <div class="col-12 text-center" style="min-height: 58px">
                    <span class="card-title">{{insurance.title}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center"  style="min-height: 110px">
                    <span v-html="insurance.description" class="txt-body"/>
                </div>
                <div class="col-12">
                    <hr style="border: solid 1px #c2c2c2;"/>
                </div>
                <div class="col-12" style="min-height: 50px">
                    <div class="row">
                        <div class="col-7">
                            <span class="total-transfers">Importe total</span>
                        </div>
                        <div class="col-5">
                            <div class="row">
                                <div class="col-12 text-center" v-if="havePromotion">
                                    <span class="text-price-promotion">
                                        {{pricePromotion}}€ 
                                    </span>
                                </div>
                                <div class="col-12" style="text-align: right; padding-right: 50px;">
                                    <span :class="havePromotionTextCss">{{insurance.price}}€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center" style="padding: 10px;">
                    <ButtonOrange 
                    label="Seleccionar"
                    @onClick="onSelectInsurance"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center" style="padding: 10px;">
                    <ButtonTransparent 
                        label="Más informacion"
                        @onClick="onMoreInfo" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonOrange from "./ButtonOrange.vue"
import ButtonTransparent from "./ButtonTransparent.vue"
import IcFlechaPequenaAbajo from "./Icons/IcFlechaPequenaAbajo.vue"

export default {
    name: 'CardService',
    components: {
        ButtonOrange,
        ButtonTransparent,
        IcFlechaPequenaAbajo,
    },
    props: {
        insurance: Object,
    },
    methods: {
        onMoreInfo() {
            this.$emit("onMoreInfo");
        },
        onSelectInsurance() {
            this.$emit("onSelectInsurance", this.insurance.id);
        },
    },
    computed: {
        havePromotion() {
            return this.insurance.discount > 0;
        },
        havePromotionCss() {
            return this.insurance.discount > 0 ? 'card-price' : 'card-promotion-price';
        },
        havePromotionTextCss() {
            return this.insurance.discount > 0 ?  'text-price' : 'text-price-promotion';
        },
        pricePromotion() {
            var promoDiscount = (this.insurance.price*(this.insurance.discount/100)); 
            var pricePromo = (this.insurance.price - promoDiscount);
            return parseFloat(pricePromo).toFixed(2);
        },
        isDefaultClass() {
            return this.insurance.default ? 'box-discount-dafault' : 'box-discount';
        }
    }
}
</script>

<style scoped>
.text-price {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.text-price-promotion {
    color: #fe3a00;
    font-size: 20px;
    text-align: right;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-family: 'Raleway';
}

.total-transfers {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-transform: uppercase;
}

.txt-body {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Raleway';
}

.card-title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.icon-more {
    color: #c2c2c2;
    font-size: 40px;
    font-family: 'Raleway';

}

.box-discount {
    margin-top: -17px;
    margin-left: -17px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: black;
}

.box-discount-dafault {
    margin-top: -17px;
    margin-left: -17px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fe3a00;
}

.txt-discount-description {
    font-family: 'Raleway';
    font-size: 9px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.txt-discount {
    margin: 2px;
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

</style>
