<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M11,9 L13,9 L13,7 L11,7 L11,9 Z M11,17 L14,17 L14,15 L13,15 L13,11 L10,11 L10,13 L11,13 L11,17 Z M12,2 C6.48000002,2 2,6.48000002 2,12 C2,17.5200005 6.48000002,22 12,22 C17.5200005,22 22,17.5200005 22,12 C22,6.48000002 17.5200005,2 12,2 C8.32000001,2 8.32000001,2 12,2 Z M4,12 C4,16.4099998 7.59000015,20 12,20 C16.4099998,20 20,16.4099998 20,12 C20,7.59000015 16.4099998,4 12,4 C7.59000015,4 4,7.59000015 4,12 C4,14.9399999 4,14.9399999 4,12 Z" id="attention-/-info_circle_outline" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_informacion'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
