<template>
    <div class="home">
        <NavBar/>
        <div class="header-panel">
            <SubMenu dates="true" on-select="1"/>
            <HeaderSlider/>
            <FormDates/>
        </div>
        <div class="home-title" v-html="translate.home.titles[0]" v-on:dblclick="onEditTitle">
        </div>
        <div class="container">
            <div class="row" style="margin-bottom:60px">
                <div class="col-ms-12 col-md-3" v-for="(item, index) in items" :key="index">
                    <div class="card text-center">
                        <div class="combined-shape text-center">
                            <img :src="item.icon" alt="Icono" />
                        </div>
                        <div class="card-body">
                            <label class="card-txt-title">{{item.title}}</label>
                            <div class="cart-txt-body" v-html="item.description"></div>
                        </div>
                        <div class="card-footer bg-orange">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AboutUsSlider/>
        <SubFooter/>
        <Footer/>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12 h-100" style="width: 500px; height: 200px !important; margin-bottom: 100px !important">
                <textarea v-model="translate.home.titles[0]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import FormDates from '@/components/Forms/FormDates.vue'
import HeaderSlider from '@/components/HeaderSlider.vue'
import AboutUsSlider from '@/components/AboutUsSlider.vue'
import IcConductorGrande from '@/components/Icons/IcConductorGrande.vue'
import IcCocheEuroGrande from '@/components/Icons/IcCocheEuroGrande.vue'
import IcCocheParkingGrande from '@/components/Icons/IcCocheParkingGrande.vue'
import IcConductorEstrellaGrande from '@/components/Icons/IcConductorEstrellaGrande.vue'

export default {
    name: 'HomeView',
    components: {
        Footer,
        NavBar,
        SubMenu,
        SubFooter,
        FormDates,
        HeaderSlider,
        AboutUsSlider,
        IcConductorGrande,
        IcCocheEuroGrande,
        IcCocheParkingGrande,
        IcConductorEstrellaGrande,
    },
    data() {
        return {
            items: [],
            indexEdit: 0,
            showEdit: false,
        }
    }, 
    methods: {
        onEditTitle() {
            if (this.isEditing) {
                this.indexEdit = 0;
                this.showEdit = true;
            }
        },
        saveTranslate(value) {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
        },
        getTransfers() {
            const self = this;
            axios.get(`/transfers/${this.company.id}`)
                .then(response => {
                    self.items = response.data.data;
                }).catch(() => {
                    console.error("Error")
                });
        },
    },
    computed: {
        ...mapState(['translate', 'isEditing', 'company'])
    },
    mounted() {
        this.getTransfers();
    }
}
</script>

<style scoped>
.header-panel {
    position: relative;
}

.card-footer:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #fe3a00;
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
}

.cart-txt-body {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    margin: 20px 32px 20px;
}

.card-txt-title {
    margin: 17px 49px 10px;
    font-family: 'Raleway';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.combined-shape {
    height: 48px;
    margin-top: 29px;
}

.card {
    margin-bottom: 20px;
    min-height: 344px;
    border-radius: 20px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.home-title {
    margin: 20px;
    margin-top: 60px;
    margin-bottom: 40px;
    align-content: center;
    font-family: 'Raleway';
    font-size: 36px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fe3a00;
}

.home-title .text-style-1 {
    color: #000;
}

</style>
