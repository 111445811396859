<template>
    <div class="container">
        <div class="home-title" v-html="translate.home.titles[1]" v-on:dblclick="onEditTitle">
        </div>
        <agile 
            class="main"
            ref="main"
            :options="options1" 
            :as-nav-for="asNavFor1" 
        >
            <div class="slide" v-for="(slide, index) in slides" :key="index" :class="`slide--${index} slide`"> 
                <img :src="slide" class="rounded max-height">
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </agile>
        <agile 
            ref="thumbnails" 
            :options="options2" 
            :as-nav-for="asNavFor2"
            class="scroll-horizontal"
        >
            <div class="slide slide--thumbniail thumbnail" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)">
                <img :src="slide" class="rounded thumbnail">
            </div>
        </agile>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12 h-100" style="width: 500px; height: 200px !important; margin-bottom: 100px !important">
                <textarea v-model="translate.home.titles[1]" rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import { VueAgile } from 'vue-agile'

export default {
    name: 'AboutUsSlider',
    components: {
        agile: VueAgile
    },
    data () {
        return {
            indexEdit: 0,
            showEdit: false,
            asNavFor1: [],
            asNavFor2: [],
            options1: {
                dots: false,
                fade: false,
                navButtons: false,
                pauseOnDotsHover: true,
            },

            options2: {
                dots: false,
                autoplay: true,
                infinite: true,
                centerMode: true,
                navButtons: false,
                pauseOnDotsHover: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 5,
                        }
                    },

                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 9,
                        }
                    }
                ]
            },
            slides: [
                require('@/assets/images/img_parking_1.jpg'),
                require('@/assets/images/img_parking_2.jpg'),
                require('@/assets/images/img_parking_3.jpg'),
                require('@/assets/images/img_parking_4.jpg'),
                require('@/assets/images/img_parking_5.jpg'),
                require('@/assets/images/img_parking_6.jpg'),
                require('@/assets/images/img_parking_7.jpg'),
                require('@/assets/images/img_parking_8.jpg'),
                require('@/assets/images/img_parking_9.jpg'),
            ]
        }
    },
    mounted () {
        this.asNavFor1.push(this.$refs.thumbnails)
        this.asNavFor2.push(this.$refs.main)
    },
    methods: {
        onEditTitle() {
            if (this.isEditing) {
                this.indexEdit = 0;
                this.showEdit = true;
            }
        },
        saveTranslate(value) {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
        },
    },
    computed: {
        ...mapState(['translate', 'isEditing'])
    },
}
</script>

<style scoped>
.home-title {
    margin: 20px;
    margin-top: 60px;
    margin-bottom: 40px;
    align-content: center;
    font-family: 'Raleway';
    font-size: 36px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fe3a00;
}

.home-title .text-style-1 {
    color: #000;
}

.scroll-horizontal {
    margin-bottom: 90px;
}

.scroll-horizontal > div {
    overflow-x: scroll;
}

.scroll-horizontal > div::-webkit-scrollbar {
  height: 4px;
}
 
.scroll-horizontal > div::-webkit-scrollbar-track {
  height: 2px;
  background-color: #ededed;
}
 
.scroll-horizontal > div::-webkit-scrollbar-thumb {
  height: 4px;
  background-color: #fe3a00;
}

.thumbnail {
    margin: 0 5px;
    object-fit: cover;
    height: 64px !important;
    width: 100px !important;
    margin-bottom: 10px !important;
}

.slide {
    height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.slide > img{
    height: 500px;
    width:100%;
    object-fit: cover;
    border-radius: 20px;
}

.booking-now {
    z-index:1;
    font-family: 'Raleway';
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    color: black;
    position:relative;
}
.Reserva-tu-plaza-ya .text-style-1 {
    color: #fe3a00;
}
</style>
