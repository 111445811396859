<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M9,2 L9,4 L15,4 L15,2 L17,2 L17,4 L19,4 C20.1030006,4 21,4.89699984 21,6 L21,20 C21,21.1030006 20.1030006,22 19,22 L5,22 C3.89699984,22 3,21.1030006 3,20 L3,6 C3,4.89699984 3.89699984,4 5,4 L7,4 L7,2 L9,2 Z M19.0002861,10 L5,10 L5,20 L19.0009995,20 L19.0002861,10 Z M10.1733563,12.3466638 C10.565874,12.0358383 11.1377194,12.0617404 11.5003491,12.4243701 C11.8908734,12.8148944 11.8908734,13.4480594 11.5003491,13.8385837 L11.5003491,13.8385837 L11.3401077,13.9994769 L15.6751077,14 L15.7917289,14.0067277 C16.2890675,14.0644928 16.6751077,14.4871642 16.6751077,15 C16.6751077,15.5522847 16.2273925,16 15.6751077,16 L15.6751077,16 L11.5211077,15.9994769 L11.5697714,16.0551788 C11.8805968,16.4476964 11.8546947,17.0195418 11.492065,17.3821715 C11.1015407,17.7726958 10.4683757,17.7726958 10.0778514,17.3821715 L10.0778514,17.3821715 L8.46438409,15.7693655 C8.40356534,15.7307176 8.34601002,15.6848568 8.29293624,15.631783 C8.0929116,15.4317584 7.9953386,15.1680784 8.00017103,14.9061998 C7.99600355,14.6449543 8.09359195,14.382387 8.29293624,14.1830427 C8.34598577,14.1299932 8.40351276,14.08415 8.46430075,14.0455132 L8.46430075,14.0455132 L10.0861355,12.4243701 Z M19,6 L5,6 L5,8 L19.0001431,8 L19,6 Z" id="Combined-Shape"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_calendario_flecha_izquierda'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
