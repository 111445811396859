<template>
    <div class="card" style="margin: 10px;">
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <div class="row box-discount">
                        <div class="col-12">
                            <div class="txt-discount">-{{company.discount}}%</div>
                        </div>
                        <div class="col-12 txt-discount-description">
                            ¡Solo hoy!
                        </div>
                    </div>
                </div>
                <div class="offset-7 col-2">
                    <ic-circulo-flecha-derecha icon-color="#5d5d5d" v-show="!showDetails" @click="onClickShowDetail"/>
                    <ic-circulo-flecha-izquierda icon-color="#5d5d5d" v-show="showDetails" @click="onClickShowDetail"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <img :src='company.logo' alt='Logo' style="margin:10px; height: 75px">
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px; width:118% !important" v-show="showDetails">
                <div class="accordion" id="accordionExample" style="margin-left: -15px">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" 
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" 
                                @click="showHideCollapse(1)"
                            >
                                CARACTERÍSTICAS
                            </button>
                        </h2>
                        <div id="collapseOne" ref="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body" v-html="company.features2">
                                
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                @click="showHideCollapse(2)"
                            >
                                LOCALIZACIÓN
                            </button>
                        </h2>
                        <div id="collapseTwo" ref="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body" v-html="company.location">
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                                @click="showHideCollapse(3)"
                            >
                                HORARIOS
                            </button>
                        </h2>
                        <div id="collapseThree" ref="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body" v-html="company.schedule">
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                                data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"
                                @click="showHideCollapse(4)"
                            >
                                SERVICIOS
                            </button>
                        </h2>
                        <div id="collapseFour" ref="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body" v-html="company.services">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px" v-show="!showDetails">
                <div class="col-12 text-center">
                    <div class="row">
                        <div v-if="havePromotion" class="col-12 text-center">
                            <label class="card-header-text">Precio Promocional</label>
                        </div>
                        <div v-if="havePromotion" class="col-12 text-center">
                            <label class="card-promotion-price">{{pricePromotion}}€</label>
                        </div>
                        <div class="col-12 text-center">
                            <label class="havePromotionCss">{{company.price}}€</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="!showDetails">
                <div class="col-12 text-center">
                    <span v-html="company.features" />
                </div>
            </div>
            <div class="row" v-show="!showDetails">
                <div class="col-12 text-center">
                    <div class="container">
                        <div class="row justify-content-md-center">
                            <div class="col-1">
                                <ic-estrella icon-color="#fe3a00"/>
                            </div>
                            <div class="col-1">
                                <ic-estrella icon-color="#fe3a00"/>
                            </div>
                            <div class="col-1">
                                <ic-estrella icon-color="#fe3a00"/>
                            </div>
                            <div class="col-1">
                                <ic-estrella icon-color="#fe3a00"/>
                            </div>
                            <div class="col-1">
                                <ic-estrella icon-color="#fe3a00"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="!showDetails">
                <div class="col-12 text-center">
                    <span class="text-comentarios">Bueno - 0 comentarios</span>
                </div>
            </div>
            <div class="row" v-show="!showDetails">
                <div class="col-12 text-center" style="padding: 10px 40px;">
                    <ButtonOrange 
                        label="Seleccionar"
                        @onClick="onSelectParking"
                    />
                </div>
            </div>
            <div class="row" v-show="!showDetails">
                <div class="col-12 text-center" style="padding: 10px 40px;">
                    <ButtonTransparent 
                        label="Más informacion"
                        @onClick="onMoreInfo" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonOrange from "./ButtonOrange.vue"
import ButtonTransparent from "./ButtonTransparent.vue"
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'
import IcCirculoFlechaIzquierda from '@/components/Icons/IcCirculoFlechaIzquierda.vue'

export default {
    name: 'CardParking',
    components: {
        IcEstrella,
        ButtonOrange,
        ButtonTransparent,
        IcCirculoFlechaDerecha,
        IcCirculoFlechaIzquierda
    },
    data() {
        return {
            showDetails: false,
        }
    },
    props: {
        company: Object,
    },
    methods: {
        showHideCollapse(index) {
            var collapseOne = this.$refs.collapseOne;
            var collapseTwo = this.$refs.collapseTwo;
            var collapseFour = this.$refs.collapseFour;
            var collapseThree = this.$refs.collapseThree;

            console.log("collapseOne", collapseOne);

            switch(index) {
                case 1:
                    collapseTwo.classList.remove("show");
                    collapseTwo.classList.add('hide');
                    collapseFour.classList.remove("show");
                    collapseFour.classList.add('hide');
                    collapseThree.classList.remove("show");
                    collapseThree.classList.add('hide');
                    break;
                case 2:
                    collapseOne.classList.remove("show");
                    collapseOne.classList.add('hide');
                    collapseFour.classList.remove("show");
                    collapseFour.classList.add('hide');
                    collapseThree.classList.remove("show");
                    collapseThree.classList.add('hide');
                    break;
                case 3:
                    collapseTwo.classList.remove("show");
                    collapseTwo.classList.add('hide');
                    collapseFour.classList.remove("show");
                    collapseFour.classList.add('hide');
                    collapseOne.classList.remove("show");
                    collapseOne.classList.add('hide');
                    break;
                case 4:
                    collapseTwo.classList.remove("show");
                    collapseTwo.classList.add('hide');
                    collapseThree.classList.remove("show");
                    collapseThree.classList.add('hide');
                    collapseOne.classList.remove("show");
                    collapseOne.classList.add('hide');
                    break;
            }
        },
        onClickShowDetail() {
            this.showDetails = !this.showDetails;
        },
        onMoreInfo() {
            this.$emit("onMoreInfo", this.company);
        },
        onSelectParking() {
            this.$emit("onSelectParking", this.company);
        },
    },
    computed: {
        havePromotion() {
            return this.company.discount > 0;
        },
        havePromotionCss() {
            return this.company.discount > 0 ? 'card-price' : 'card-promotion-price';
        },
        pricePromotion() {
            var promoDiscount = (this.company.price*(this.company.discount/100)); 
            var pricePromo = (this.company.price - promoDiscount);
            return parseFloat(pricePromo).toFixed(2);
        }
    }
}
</script>

<style scoped>
.havePromotionCss {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-decoration: line-through !important;
}

.text-comentarios {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Raleway';
}

.box-discount {
    margin-top: -17px;
    margin-left: -17px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fe3a00;
}

.txt-discount-description {
    font-family: 'Raleway';
    font-size: 9px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.txt-discount {
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.card-price {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5d5d5d;
}

.card-promotion-price {
    font-family: 'Raleway';
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.card-header-text {
    text-transform: uppercase;
    font-family: 'Raleway';
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.footer-text {
    color: #000;
    font-size: 10px;
    line-height: 1.6;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.card-footer:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #fe3a00;
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.15);
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #000;
    height: 32px;
}
</style>
