<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M2,12 C2,6.47715187 6.47715378,2 12,2 C17.5228486,2 22,6.47715378 22,12 C22,17.5228486 17.5228467,22 12,22 C6.47715187,22 2,17.5228467 2,12 C2,8.31810125 2,8.31810125 2,12 Z M12,6.92545488 C12.8284271,6.92545488 13.5,7.59702776 13.5,8.42545488 L13.5,13.9254549 L13.5,13.9254549 L10.5,13.9254549 L10.5,8.42545488 C10.5,7.59702776 11.1715729,6.92545488 12,6.92545488 Z M12.4681646,17.6781756 L13.5,14.9266146 L13.5,14.9266146 L10.5,14.9266146 L11.5318354,17.6781756 C11.6287955,17.9367358 11.9170016,18.0677386 12.1755617,17.9707785 C12.3107734,17.9200741 12.4174602,17.8133873 12.4681646,17.6781756 Z M12,4 C16.4182792,4 20,7.58172321 20,12 C20,16.4182792 16.4182773,20 12,20 C7.58172131,20 4,16.4182773 4,12 C4,7.58172131 7.58172321,4 12,4 Z" id="arrow-/-circle_chevron_right" transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_circulo_lapiz'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
