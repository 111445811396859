<template>
    <div>
        <NavBar/>
        <Header/>
        <div class="container">
            <div class="row pt-5">
                <div class="offset-1 col-10">
                    <div class="card mb-1">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-10 text-left p-1" v-on:dblclick="setEdit(10)">
                                    <ic-mapa icon-color="#fe3a00"/>
                                    &nbsp;
                                    <span class="txt-title">
                                        {{translate.parkings_info[10]}}
                                    </span>
                                </div>
                                <div class="col-1 text-right">
                                    <div @click="onChangeShowDetail">
                                        <ic-flecha-pequena-arriba icon-color="#5d5d5d" v-if="showDetail"/>
                                        <ic-flecha-pequena-abajo icon-color="#5d5d5d" v-else />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-4" v-if="showDetail" v-on:dblclick="setEditTextarea(11)" v-html="translate.parkings_info[11]" />
                        <div class="card-footer bg-orange" v-if="showDetail">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AboutUsSlider/>
        <Footer/>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.parkings_info[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.parkings_info[indexEdit]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import IcMapa from '@/components/Icons/IcMapa.vue'
import Header from '@/components/ParkingInfo/Header.vue'
import AboutUsSlider from '@/components/AboutUsSlider.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import IcFlechaPequenaAbajo from "@/components/Icons/IcFlechaPequenaAbajo.vue"
import IcFlechaPequenaArriba from "@/components/Icons/IcFlechaPequenaArriba.vue"

export default {
    name: 'ParkingInfo',
    data() {
        return {
            showEdit: false,
            indexEdit: null,
            showDetail: false,
            showEditTexarea: false,
        }
    },
    components: {
        Header,
        IcMapa,
        Footer,
        NavBar,
        AboutUsSlider,
        IcInformacion,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        setEdit(index) {
            const self = this;
            if (self.isEditing) { 
                self.showEdit = true; 
                self.indexEdit = index;
            }
        },
        setEditTextarea(index) {
            const self = this;
            if (self.isEditing) { 
                self.showEditTexarea = true; 
                self.indexEdit = index;
            }
        },
        onChangeShowDetail() {
            this.showDetail = !this.showDetail;
        },
    },
    computed: {
        ...mapState(['translate', 'isEditing'])
    },
}
</script>

<style scoped> 
.txt-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card-body-text > span {
    font-weight: 800;
}

.card-body-text {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.card-footer:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #fe3a00;
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
}

.card-header {
    height: 48px;
    background-color: white;
}

.card-header .fa {
  transition: .3s transform ease-in-out;
}

.card-header .collapsed .fa {
  transform: rotate(90deg);
}
</style>
