<template>
    <div class="home">
        <NavBar/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-html="translate.about.title" v-on:dblclick="setEditTitle"/>
                    <div class="col-12 text-center" style="margin-top: 16px">
                        <span class="header-body" v-html="translate.about.description" v-on:dblclick="setEditDescription" />
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        <div class="row">
                            <div class="offset-1 col-10 offset-md-4 col-md-4">
                                <form class="input-group mb-3 needs-validation" @submit.prevent="onFindFaqs" novalidate>
                                    <input type="text" class="form-control" placeholder="Dinos que buscas" 
                                        v-model="findFaq" required>
                                    <button class="btn btn-outline-secondary bg-white" type="submit">
                                        <ic-lupa/>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-4"> 
            <div class="row" v-for="(item, index) in items" :key="index">
                <SupportItem :item="item"/>
            </div>
        </div>
        <div class="showFull">
            <SubFooter/>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.about.description">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.about.title"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import IcLupa from '@/components/Icons/IcLupa.vue'
import SubFooter from '@/components/SubFooter.vue'
import SupportItem from '@/components/SupportItem.vue'


export default {
    name: 'SupportView',
    data() {
        return {
            items: [],
            findFaq: null,
            showEdit: false,
            showEditTexarea: false,
        }
    }, 
    components: {
        Footer,
        IcLupa,
        NavBar,
        SubFooter,
        SupportItem,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        setEditDescription() {
            const self = this;
            if (self.isEditing) { 
                self.showEdit = true; 
            }
        },
        setEditTitle() {
            const self = this;
            if (self.isEditing) { 
                self.showEditTexarea = true; 
            }
        },
        onFindFaqs() {
            if (this.findFaq != null) {
            const self = this;
            axios.get(`/faqs?q=${this.findFaq}`)
                .then(response => {
                    self.items = response.data.data;
                }).catch(() => {
                    console.log("error");
                });
            }
        }
    },
    mounted() {
        let self = this;
        axios.get('/faqs')
            .then(response => {
                self.items = response.data.data;
            }).catch(() => {
                console.log("error");
            });
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing'])
    }
}
</script>

<style scoped> 
.header-text {
    font-family: 'Raleway';
    font-size: 36px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
}

.header-body {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: black;
}

.showFull {
    display: none;
}

@media (min-width: 575px) { 
    .header-support {
        background-size: cover;
        background-color: #c2c2c2;
        background-repeat: no-repeat;
        background-image: url(../assets/images/img_ayuda_desktop.jpg);
    }

    .header-text {
        color: white !important;
    }

    .header-body {
        color: white !important;
    }

    .showFull {
        display: initial;
    }
}

.btn-outline-secondary {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}



.header-text > span {
    color: #fe3a00;
}

</style>
