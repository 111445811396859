<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :viewBox="`0 0 ${width} ${height}`" 
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Oval" :fill="iconColorCircle" cx="20" cy="20" r="20"></circle>
            <path :fill="iconColor" d="M27.7911922,16.9857 L30.9163017,16.9857 L30.9163017,13.99285 L27.7911922,13.99285 L27.7911922,16.9857 Z M26.7997057,26.43515 C25.952738,26.43515 25.2651789,25.7764 25.2651789,24.96465 C25.2651789,24.1529 25.952738,23.495 26.7997057,23.495 C27.6475493,23.495 28.3351084,24.1529 28.3351084,24.96465 C28.3351084,25.7764 27.6475493,26.43515 26.7997057,26.43515 L26.7997057,26.43515 Z M22.5832603,16.9857 L25.7083698,16.9857 L25.7083698,13.99285 L22.5832603,13.99285 L22.5832603,16.9857 Z M17.3744526,16.9857 L20.4995621,16.9857 L20.4995621,13.99285 L17.3744526,13.99285 L17.3744526,16.9857 Z M14.250219,26.43515 C13.4023754,26.43515 12.7148162,25.7764 12.7148162,24.96465 C12.7148162,24.1529 13.4023754,23.495 14.250219,23.495 C15.0980626,23.495 15.7847458,24.1529 15.7847458,24.96465 C15.7847458,25.7764 15.0980626,26.43515 14.250219,26.43515 L14.250219,26.43515 Z M10.0836983,19.9777 L10.0836983,13.99285 L15.2916302,13.99285 L15.2916302,16.9857 L10.0836983,19.9777 Z M30.9163017,11 L10.0836983,11 C8.93367901,11 8,11.8942 8,12.99495 L8,23.9676 C8,24.51925 8.46683951,24.96465 9.0414112,24.96465 L11.08044,24.96465 C11.08044,26.6417 12.4993519,28 14.2493431,28 C16.0002102,28 17.4199979,26.6417 17.4199979,24.96465 L23.5800021,24.96465 C23.5800021,26.6417 24.9989139,28 26.749781,28 C28.5006481,28 29.91956,26.6417 29.91956,24.96465 L31.9585888,24.96465 C32.5331605,24.96465 33,24.51925 33,23.9676 L33,12.99495 C33,11.8942 32.066321,11 30.9163017,11 L30.9163017,11 Z" id="Fill-7" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_bola_autobus'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        iconColor: {
            type: String,
            default: '#FE3A00'
        },
        iconColorCircle: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>
