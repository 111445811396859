<template>
    <div class="offset-1 col-10">
        <div class="card mb-4">
            <div class="card-header">
                <div class="row">
                    <div class="col-10 text-left">
                        <span class="txt-title">
                            <ic-informacion icon-color="#fe3a00"/>
                            &nbsp;
                            {{item.question}}
                        </span>
                    </div>
                    <div class="col-2 text-right">
                        <div @click="onChangeShowDetail">
                            <ic-flecha-pequena-arriba icon-color="#5d5d5d" v-if="showDetail"/>
                            <ic-flecha-pequena-abajo icon-color="#5d5d5d" v-else />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="showDetail">
                <div class="m-4"> 
                    <span v-html="item.answer"></span>
                </div>
            </div>
            <div class="card-footer bg-orange" v-if="showDetail">
            </div>
        </div>
    </div>
</template>

<script>
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import IcFlechaPequenaAbajo from "@/components/Icons/IcFlechaPequenaAbajo.vue"
import IcFlechaPequenaArriba from "@/components/Icons/IcFlechaPequenaArriba.vue"

export default {
    name: 'SupportItem',
    components: {
        IcInformacion,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
    },
    data() {
        return {
            showDetail: false
        }
    },
    props: {
        item: Object,
    },
    methods: {
        onChangeShowDetail() {
            this.showDetail = !this.showDetail;
        },
    },
}
</script>

<style scoped>
.txt-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.card-footer:last-child {
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    background-color: #fe3a00;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.card-header {
    height: 48px;
    background-color: white;
}

.card-header .fa {
  transition: .3s transform ease-in-out;
}

.card-header .collapsed .fa {
  transform: rotate(90deg);
}
</style>
