<template>
    <div class="home">
        <NavBar/>
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu insurance="true" on-select="5"/>
        </div>
        <ResumeBookingHeader/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-html="translate.insurance[0]" v-on:dblclick="setEdit(0)" />
                    <div class="col-12 text-center" style="margin-top: 16px" v-html="translate.insurance[1]" v-on:dblclick="setEdit(1)" />
                </div>
            </div>
        </div>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-md-center">
                            <div class="col-12 col-sm-6 col-md-3 pb-5" v-for="(item, index) in items" :key="index">
                                <CardInsurance 
                                    :insurance="item" 
                                    @onMoreInfo="onMoreInfo" 
                                    @onSelectInsurance="onSelectInsurance"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.insurance[indexEdit]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import BarPassager from '@/components/BarPassager.vue'
import CardInsurance from '@/components/CardInsurance.vue'
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import ButtonTransparent from "@/components/ButtonTransparent.vue"
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'

export default {
    name: 'InsuranceViews',
    data() {
        return {
            items: [],
            indexEdit: null,
            showEditTexarea: false,
        }
    }, 
    components: {
        Footer,
        NavBar,
        SubMenu,
        SubFooter,
        IcEstrella,
        BarPassager,
        CardInsurance,
        IcInformacion,
        ButtonTransparent,
        ResumeBookingHeader,
        IcCirculoFlechaDerecha,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTexarea = false;
        },
        setEdit(index) {
            const self = this;
            if (self.isEditing) { 
                self.indexEdit = index; 
                self.showEditTexarea = true; 
            }
        },
        onMoreInfo() {
            this.$router.push('/parkings/information')
        },
        onSelectInsurance(value) {
            let booking = localStorage.getItem('booking');
            const objBooking = JSON.parse(booking);
            const insurance = this.items.find(element => element.id === value);
            objBooking.insurance = insurance;
            objBooking.insurance_id = value;
            const bookingJson = JSON.stringify(objBooking);
            localStorage.setItem('booking', bookingJson);
            this.$router.push('/confirmation')
        },
        getInsurances() {
            const self = this;
            axios.get(`/insurances/${this.company.id}`)
                .then(response => {
                    self.items = response.data.data;
                }).catch(() => {
                    console.error("Error")
                });
        }
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing'])
    },
    mounted() {
        this.getInsurances()
    }
}
</script>

<style scoped> 
.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-body > span{
    font-weight: bold;
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
