<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M12,14 C14.2091389,14 16,12.2091389 16,10 C16,7.79086113 14.2091389,6 12,6 C9.79086113,6 8,7.79086113 8,10 C8,12.2091389 9.79086113,14 12,14 C13.4727592,14 13.4727592,14 12,14 Z M12,12 C13.1045694,12 14,11.1045694 14,10 C14,8.89543056 13.1045694,8 12,8 C10.8954306,8 10,8.89543056 10,10 C10,11.1045694 10.8954306,12 12,12 C12.7363796,12 12.7363796,12 12,12 Z M17.9261141,20.0557022 C20.396986,18.2349796 22,15.3046932 22,12 C22,6.47715235 17.5228472,2 12,2 C6.47715235,2 2,6.47715235 2,12 C2,15.3046904 3.60301447,18.2349777 6.07388306,20.0557003 C6.11933804,20.0891953 6.16508675,20.1223125 6.2111249,20.1550522 C6.65997267,20.4742432 7.13630247,20.7573071 7.63587379,21 C8.9546361,21.6406574 10.4353542,22 12,22 C13.5646458,22 15.0453625,21.6406574 16.3641253,21 C16.3909302,20.9869785 16.4176674,20.9738407 16.4443378,20.9605865 C16.9657145,20.7014923 17.4612732,20.3982315 17.9261141,20.0557022 C19.573362,18.8418872 19.573362,18.8418872 17.9261141,20.0557022 Z M17.228713,18.0549698 C18.9259834,16.5880041 20,14.4194269 20,12 C20,7.58172178 16.4182777,4 12,4 C7.58172178,4 4,7.58172178 4,12 C4,14.419425 5.0740149,16.5880003 6.77128363,18.054966 C7.80066252,16.2312946 9.75653887,15 12,15 C14.2434587,15 16.1993341,16.2312975 17.228713,18.0549698 C18.3602266,17.0769927 18.3602266,17.0769927 17.228713,18.0549698 Z M15.5566797,19.1678677 C14.8920803,17.8803005 13.5488424,17 12,17 C10.4511557,17 9.10791826,17.8803015 8.44331884,19.1678677 C9.51467323,19.7004967 10.7223597,20 12,20 C13.2776394,20 14.4853258,19.7004967 15.5566797,19.1678677 C15.1136134,18.3094896 15.1136134,18.3094896 15.5566797,19.1678677 Z" id="user-/-user_circle" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_circulo_persona'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
