<template>
    <div class="home">
        <NavBar/>
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu register="true" on-select="3"/>
        </div>
        <ResumeBookingHeader/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-on:dblclick="setEditTextarea(0)" v-html="translate.register[0]" />
                    <div class="col-12 text-center" style="margin-top: 16px" v-on:dblclick="setEditTextarea(1)">
                        <span class="header-body">
                            {{translate.register[1]}}
                        </span>
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        <div class="row">
                            <div class="offset-1 col-10  offset-md-4 col-md-4">
                                <form class="input-group mb-3 needs-validation" @submit.prevent="onFindEmail" novalidate>
                                    <input type="text" class="form-control" placeholder="Ingrese su email" 
                                        v-model="findEmail" required>
                                    <button class="btn btn-outline-secondary" type="submit">
                                        <ic-lupa/>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <form class="row g-3 needs-validation" ref="formClient" novalidate 
                            @submit.prevent="onSubmitClient">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 mb-4">
                                    <span class="form-txt-info" v-on:dblclick="setEditTextarea(2)">
                                        <ic-iidentificacion icon-color="#fe3a00"/> 
                                        {{translate.register[2]}}
                                    </span>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Nombre" aria-label="Nombre" 
                                        v-model="booking.client.name" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Apellidos" 
                                        aria-label="Apellidos" v-model="booking.client.last_name" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="DNI/NIE" 
                                        aria-label="DNI/NIE" v-model="booking.client.dni" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Código postal" 
                                        aria-label="Código postal" v-model="booking.client.postal_code" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="tel" class="form-control" placeholder="Móvil" aria-label="Móvil"
                                        v-model="booking.client.phone" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="email" class="form-control" placeholder="Email" aria-label="Email" 
                                        v-model="booking.client.email" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="email" :class="`form-control ${!confirmEmail ? 'is-invalid' : ''}`" 
                                        placeholder="Repite email" aria-label="Repite email" 
                                        v-model="booking.client.confirm_email"/>
                                    <div class="text-danger" v-if="!confirmEmail">
                                        Sus emails no son iguales..
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11 mb-4" v-on:dblclick="setEditTextarea(3)">
                                    <span class="form-txt-info">
                                        <ic-documento icon-color="#fe3a00"/> 
                                        {{translate.register[3]}}
                                    </span>
                                </div>
                                <div class="col-1">
                                    <span class="form-txt-info" @click="onShowInvoices" v-if="!showInvoice">
                                        <ic-flecha-pequena-abajo/>
                                    </span>
                                    <span class="form-txt-info" @click="onShowInvoices" v-if="showInvoice">
                                        <ic-flecha-pequena-arriba/>
                                    </span>
                                </div>
                                <div class="col-12" v-if="!showInvoice">
                                    <hr/>
                                </div>
                                <div class="col-12" v-if="showInvoice">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 mb-4">
                                            <input type="text" class="form-control" placeholder="Nombre" aria-label="Nombre" 
                                                v-model="booking.invoice.name" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 mb-4">
                                            <input type="text" class="form-control" placeholder="CIF" aria-label="CIF" 
                                                v-model="booking.invoice.cif" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 mb-4">
                                            <input type="text" class="form-control" placeholder="Dirección" aria-label="Dirección" 
                                                v-model="booking.invoice.address" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 mb-4">
                                            <input type="text" class="form-control" placeholder="Ciudad" aria-label="Ciudad" 
                                                v-model="booking.invoice.city" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 mb-4">
                                            <input type="text" class="form-control" placeholder="Código postal" aria-label="Código postal" 
                                                v-model="booking.invoice.postal_code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-4">
                                    <span class="form-txt-info" v-on:dblclick="setEditTextarea(4)">
                                        <ic-documento icon-color="#fe3a00"/> 
                                        {{translate.register[4]}}
                                    </span>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <select class="form-select" v-model="booking.vehicle.brand_id" required>
                                        <option disabled selected hidden value="">Marca</option>
                                        <option v-for="(item, index) in brands" :key="index" :value="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Modelo" aria-label="Modelo"
                                         v-model="booking.vehicle.model" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Color" 
                                        aria-label="Color" v-model="booking.vehicle.color" required>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Matrícula" 
                                        aria-label="Matrícula" v-model="booking.vehicle.licenseplate"
                                        @change="onFineLiceseplate" required>
                                    <div class="text-danger" v-if="isValidlicenseplate">
                                        La placa que ingresó ya fue utilizada.
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-4">
                                    <span class="form-txt-info" v-on:dblclick="setEditTextarea(5)">
                                        <ic-documento icon-color="#fe3a00"/> 
                                        {{translate.register[5]}}
                                    </span>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Número de vuelo" 
                                        aria-label="Número de vuelo" v-model="booking.flight.number">
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Compañía" 
                                        aria-label="Compañía" v-model="booking.flight.company">
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" 
                                        placeholder="Ciudad/aeropuerto de regreso" 
                                        aria-label="Ciudad/aeropuerto de regreso" v-model="booking.flight.city">
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="País" aria-label="País" 
                                        v-model="booking.flight.country">
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 mb-4">
                                    <input type="text" class="form-control" placeholder="Hora de aterrizaje" 
                                        aria-label="Hora de aterrizaje" v-model="booking.flight.hour">
                                </div>
                            </div>
                        </div>
                        <div class="offset-1 col-10 offset-sm-2 col-sm-8 offset-md-4 col-md-4 ">
                            <ButtonOrange 
                                type="submit"
                                v-if="!isEditing"
                                :label="translate.register[6]"
                            />
                            <div v-else class="btn-booking" v-on:dblclick="setEditTextarea(6)">{{translate.register[6]}}</div>
                        </div>
                        <div class="offset-1 col-10 offset-sm-2 col-sm-8 offset-md-4 col-md-4 ">
                            <ButtonTransparent 
                                @onClick="onBack" 
                                v-if="!isEditing"
                                :label="translate.register[7]"
                            />
                            <div class="button-transparent" v-else v-on:dblclick="setEditTextarea(7)">{{translate.register[7]}}</div>
                        </div>
                    </form>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.register[indexEdit]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
import store from '../../../store'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import IcLupa from '@/components/Icons/IcLupa.vue'
import ButtonOrange from "@/components/ButtonOrange.vue"
import IcDocumento from '@/components/Icons/IcDocumento.vue'
import ButtonTransparent from "@/components//ButtonTransparent.vue"
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcIidentificacion from '@/components/Icons/IcIidentificacion.vue'
import IcFlechaPequenaAbajo from '@/components/Icons/IcFlechaPequenaAbajo.vue'
import IcFlechaPequenaArriba from '@/components/Icons/IcFlechaPequenaArriba.vue'

export default {
    name: 'ClientRegisterRegister',
    components: {
        Footer,
        NavBar,
        IcLupa,
        SubMenu,
        IcDocumento,
        ButtonOrange,
        ButtonTransparent,
        IcIidentificacion,
        ResumeBookingHeader,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
    },
    data() {
        return {
            brands: [],
            vehicles: [],
            findEmail: null,
            indexEdit: null,
            showInvoice: false,
            showEditTexarea: false,
            isValidlicenseplate: false,
            booking: {
                invoice: {
                    cif: null,
                    name: null,
                    city: null,
                    address: null,
                    postal_code: null,
                },
                client: {
                    id: 0,
                    dni:null,
                    email: "",
                    name: null,
                    phone: null,
                    last_name: null,
                    postal_code: null,
                    confirm_email: "",
                },
                vehicle: {
                    model: null,
                    color: null,
                    brand_id: "",
                    licenseplate: null,
                },
                flight: {
                    hour: null,
                    city: null,
                    number: null,
                    company: null,
                    country: null,
                }
            }
        }
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTexarea = false;
        },
        setEditTextarea(index) {
            const self = this;
            if (self.isEditing) { 
                self.indexEdit = index;
                self.showEditTexarea = true; 
            }
        },
        onBack() {
            this.$router.push('/parkings')
        },
        onShowInvoices() {
            this.showInvoice = !this.showInvoice;
        },
        onSubmitClient() {
            if (this.$refs.formClient.checkValidity()) {
                const jsonBooking = localStorage.getItem('booking');
                const currentBooking = JSON.parse(jsonBooking);
                currentBooking.client  = this.booking.client;
                currentBooking.flight  = this.booking.flight;
                currentBooking.vehicle  = this.booking.vehicle;
                currentBooking.invoice  = this.booking.invoice;
                const bookingJson = JSON.stringify(currentBooking);
                console.log(bookingJson);
                localStorage.setItem('booking', bookingJson);
                this.$router.push('/services')
            }
        },
        onFineLiceseplate() {
            const self = this;
            axios.get(`vehicle/${self.booking.client.email ?? "email"}/licenseplate/${self.booking.vehicle.licenseplate}`)
                .then(response => {
                    self.isValidlicenseplate = response.data.exists;
                }).catch((ex) => {
                    console.error(ex);
                });
        },
        onFindEmail() {
            const self = this;
            axios.get(`/client/email/${this.findEmail}`)
                .then(response => {
                    if (response.data.data) {
                        console.log(response.data.data);
                        self.booking.client.id = response.data.data.id;
                        self.booking.client.dni = response.data.data.dni;
                        self.booking.client.name = response.data.data.name;
                        self.booking.client.email = response.data.data.email;
                        self.booking.client.phone = response.data.data.phone;
                        self.booking.client.last_name = response.data.data.last_name;
                        self.booking.client.postal_code = response.data.data.postal_code;
                        self.vehicles = response.data.data.vehicles;

                        if (self.vehicles.lenght > 0) {
                            self.booking.vehicle.model = self.vehicles[0].model;
                            self.booking.vehicle.color = self.vehicles[0].color;
                            self.booking.vehicle.brand_id = self.vehicles[0].brand_id;
                            self.booking.vehicle.licenseplate = self.vehicles[0].licenseplate;
                        }
                    }
                }).catch((ex) => {
                    console.error(ex);
                });
        },
        getBrands() {
            const self = this;
            axios.get('/brands')
                .then(response => {
                    self.brands = response.data.data;
                }).catch(() => {
                    store.dispatch('setCompany', null);
                });
        }
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing']),
        confirmEmail() {
            return ((this.booking.client.email.trim() == "") || (this.booking.client.email.trim() != "" 
                    && this.booking.client.email == this.booking.client.confirm_email));
        }
    },
    mounted() {
        const self = this;
        const booking = localStorage.getItem('booking');
        if (!booking) {
            this.$router.push('/')
        }

        this.getBrands();

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }
                self.validate = true;
                form.classList.add('was-validated')
            }, false)
        });

        const values = JSON.parse(localStorage.getItem('bookingClient'));

        if (values) {
            self.booking.end_date = values.end_date;
            self.booking.end_hour = values.end_hour;
            self.booking.start_date = values.start_date;
            self.booking.start_hour = values.start_hour;
            self.booking.end_minute = values.end_minute;
            self.booking.start_minute = values.start_minute;
            self.booking.area_pickup_id = values.area_pickup_id;
            self.booking.code_promotion = values.code_promotion;
            self.booking.type_vehicle_id = values.type_vehicle_id;
            self.booking.area_delivery_id = values.area_delivery_id;
        }
    }
}
</script>

<style scoped> 
.button-transparent {
    width: 100%;
    padding: 8px;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: rgb(0,0,0, .2);
}

.btn-booking {
    width: 100%;
    padding: 8px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: #fe3a00;
}

.btn-outline-secondary {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.form-txt-info {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-transform: uppercase;
}

.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
