<template>
    <button 
        :type="type" 
        @click="onClick"
        :disabled="!active"
        class="btn-booking"
    >
        {{label}}
    </button>
</template>

<script>

export default {
    name: 'ButtonOrange',
    props: {
        type: {
            type: String,
            default: 'button'
        },
        active: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Boton'
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick");
        },
    },
}
</script>

<style scoped>
.btn-booking {
    width: 100%;
    padding: 8px;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: #fe3a00;
}
</style>
