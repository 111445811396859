<template>
    <div class="text-center">
        <div class="form-signin w-100 m-auto">
            <img class="mb-4" :src='company.logo' alt="" width="72" height="57">
            <h1 class="h3 mb-3 fw-normal">Inicio de sesion</h1>
            <div class="form-floating">
                <input type="email" class="form-control" placeholder="name@example.com" v-model="email">
                <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating">
                <input type="password" class="form-control" placeholder="Password" v-model="password">
                <label for="floatingPassword">Password</label>
            </div>
            <button class="w-100 btn btn-lg btn-primary" @click="login">Ingresar</button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from '../store'
import { mapState } from 'vuex'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            message: '',
            password: '',
            showMessage: false,
        }
    },
    computed: {
        ...mapState(['company'])
    },
    methods: {
        login() {
            const self = this;
            axios.post('/admin/login', {
                email: self.email,
                password: self.password,
            }).then(response => {
                console.log(response);
                if (response.data.data.success) {
                    store.dispatch('setCurrentAdmin', true);
                    store.dispatch('setToken', response.data.data.token);
                    this.$router.push("/");
                } else {
                    self.showAlert(response.data.data.message);
                }
            })
            .catch(error => {
                store.dispatch('setCurrentAdmin', false);
                store.dispatch('setToken', null);
                this.showAlert('Incorrect username or password');
            });
        },
        showAlert(msg) {
            let title = 'Username or Password incorrect';
            let body = msg;
            let status = 'danger';
            window.alert(msg);
        },
    }
}

</script>

<style scoped>
html,
body {
    height: 100%;
}

body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
