<template>
    <div class="home">
        <NavBar/>
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu transfer="true" on-select="4"/>
        </div>
        <ResumeBookingHeader/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-html="translate.services[0]" v-on:dblclick="setEdit(0)" />
                    <div class="col-12 text-center" style="margin-top: 16px" v-html="translate.services[1]" v-on:dblclick="setEdit(1)" />
                </div>
            </div>
        </div>
        <BarPassager 
            @onChangePassagerPikup='onChangePassagerPikup' 
            @onChangePassagerDelivery='onChangePassagerDelivery'
        />
        <div class="header-support">
            <div v-if="passagerPikup == ''" class="divBlock" :style="{ height: dinamycHeight + 'px' }">
            </div>
            <div class="container" >
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-md-center" ref="infoBox">
                            <div class="col-12 col-sm-6 col-md-3 mb-5" v-for="(item, index) in items" :key="index">
                                <CardService 
                                    :service="item" 
                                    @onMoreInfo="onMoreInfo" 
                                    :active="passagerPikup != ''"
                                    :passagerPikup="passagerPikup"
                                    @onSelectService="onSelectService"
                                    :passagerDelivery="passagerDelivery"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 offset-sm-2 col-sm-8 offset-md-4 col-md-4 text-center mb-4" style="margin-top: 32px">
                        <ButtonTransparent 
                            v-if="!isEditing"
                            :label="translate.services[5]"
                            @onSelectParking="onInsurances"
                        />
                        <div class="button-transparent" v-else v-on:dblclick="setEdit(5)">{{translate.services[5]}}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.services[indexEdit]"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import BarPassager from '@/components/BarPassager.vue'
import CardService from '@/components/CardService.vue'
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import ButtonTransparent from "@/components/ButtonTransparent.vue"
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'

export default {
    name: 'ServicesView',
    data() {
        return {
            items: [],
            indexEdit: null,
            passagerPikup: "",
            dinamycHeight: null,
            passagerDelivery: "",
            showEditTexarea: false,
        }
    }, 
    components: {
        Footer,
        NavBar,
        SubMenu,
        SubFooter,
        IcEstrella,
        BarPassager,
        CardService,
        IcInformacion,
        ButtonTransparent,
        ResumeBookingHeader,
        IcCirculoFlechaDerecha,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTexarea = false;
        },
        setEdit(index) {
            const self = this;
            if (self.isEditing) { 
                self.indexEdit = index; 
                self.showEditTexarea = true; 
            }
        },
        onMoreInfo() {
            this.$router.push('/parkings/information')
        },
        onChangePassagerPikup(value) {
            this.passagerPikup = value;
        },
        onChangePassagerDelivery(value) {
            this.passagerDelivery = value;
        },
        onSelectService(value) {
            if (this.passagerPikup != "") {
                let booking = localStorage.getItem('booking');
                const objBooking = JSON.parse(booking);
                const service = this.items.find(element => element.id == value);
                objBooking.service = service;
                objBooking.service_id = value;
                objBooking.passager_pickup = this.passagerPikup;
                objBooking.passager_delivery = this.passagerDelivery;
                const bookingJson = JSON.stringify(objBooking);
                localStorage.setItem('booking', bookingJson);
                this.$router.push('/insurances')
            }
        },
        notNeedTransfer() {
            let booking = localStorage.getItem('booking');
            const objBooking = JSON.parse(booking);
            objBooking.service_id = value;
            const bookingJson = JSON.stringify(objBooking);
            localStorage.setItem('booking', bookingJson);
            this.$router.push('/insurances')
        },
        getServices() {
            const self = this;
            axios.get(`/pakages/${this.company.id}`)
                .then(response => {
                    self.items = response.data.data;
                }).catch(() => {
                    console.error("Error")
                }).finally(() => {
                    this.matchHeight();
                });
        },
        matchHeight () {
            this.dinamycHeight = this.$refs.infoBox.clientHeight + 40;
            console.log("height", this.dinamycHeight);
        }
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing']),
        blockStyle() {
            return {
                height: `${this.dinamycHeight}px`,
            }
        }
    },
    mounted() {
        const booking = localStorage.getItem('booking');
        if (!booking) {
            this.$router.push('/')
        }

        this.getServices()
    }
}
</script>

<style scoped> 
.divBlock {
    z-index: 3;
    width: 100%;
    opacity: .4;
    margin-top: -20px;
    position: absolute;
    background-color: black;
}

.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-body > span{
    font-weight: bold;
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
