<template>
    <agile 
        :arrows="false" 
        :speed="1000" 
        :fade="false" 
        :dots="false"
        :autoplay="false"
        :centerMode="true"
        :navButtons="false"
        class="main"
    >
        <div class="slide">
            <img src="../assets/images/img_hero_desktop.jpg" alt="slider1" class="img-slider">
            <div class="booking-now">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6" style="margin-top:150px">
                            <div class="row">
                                <div class="col-12">
                                    <span class="text-style-1">Reserva</span> tu plaza <br/>
                                    ya y disfruta <br>
                                    del viaje
                                </div>
                                <div class="col-12 txt-description" style="margin-top:38px">
                                    <span class="text-style-point">•</span>Situados a 5 minutos <br/>
                                    <span class="text-style-point">•</span>Reserva y cancela gratis <br/>
                                    <span class="text-style-point">•</span>Paga al salir, no antes<br/>
                                </div>
                                <div class="col-12">
                                    <div class="button-slider">
                                        CONOCE NUESTROS PARKINGS
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide">
            <img src="../assets/images/img_hero_desktop.jpg" alt="slider1" class="img-slider">
            <div class="booking-now">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6" style="margin-top:150px">
                            <div class="row">
                                <div class="col-12">
                                    <span class="text-style-1">Reserva</span> tu plaza <br/>
                                    ya y disfruta <br>
                                    del viaje
                                </div>
                                <div class="col-12 txt-description" style="margin-top:38px">
                                    <span class="text-style-point">•</span>Situados a 5 minutos <br/>
                                    <span class="text-style-point">•</span>Reserva y cancela gratis <br/>
                                    <span class="text-style-point">•</span>Paga al salir, no antes<br/>
                                </div>
                                <div class="col-12">
                                    <div class="button-slider">
                                        CONOCE NUESTROS PARKINGS
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </agile>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'HeaderSlider',
    components: {
        agile: VueAgile
    }
}
</script>

<style scoped>
.button-slider {
    color: #fff;
    height: 40px;
    margin: 20px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    line-height: normal;
    border-radius: 20px;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    background-color: rgb(255,255,255, .2);
}

.text-style-point {
    font-family: 'Raleway';
    font-weight: 900;
    color: #fe3a00;
}

.txt-description {
    font-family: 'Raleway';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.img-slider {
    position: absolute;
}
.slide {
    height: 630px;
}

.slide > img {
    height: 680px;
    width: 100%;
    object-fit: cover;
}

.booking-now {
    z-index:1;
    font-family: 'Raleway';
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    position:relative;
}

.text-style-1 {
  color: #fe3a00;
}
</style>
