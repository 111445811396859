<template> 
    <div class="home"> 
        <NavBar/> 
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu confirm="true" on-select="6"/>
        </div>
        <ResumeBookingHeader/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-on:dblclick="setEditTextarea(0)" v-html="translate.confirmation[0]" />
                    <div class="col-12 text-center" style="margin-top: 16px" v-on:dblclick="setEditTextarea(1)">
                        <span class="header-body">
                            {{translate.confirmation[1]}}
                        </span>
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        &nbsp;
                    </div>
                    <div class="col-12 pb-3">
                        <div class="row pb-3">
                            <div class="col-11 tx-headers" v-on:dblclick="setEditTextarea(2)">
                                <span>
                                    <ic-calendar icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[2]}}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Fecha de entrada al parking
                                    </div>
                                    <div class="col-12">
                                        {{fechaLLegada}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Terminal de salida vuelo
                                    </div>
                                    <div class="col-12">
                                        {{terminalSalida}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Terminal de salida vuelo
                                    </div>
                                    <div class="col-12">
                                        {{fechaSalida}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Terminal de llegada vuelo
                                    </div>
                                    <div class="col-12">
                                        {{terminalLLegada}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Nº total de días
                                    </div>
                                    <div class="col-12">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Parking seleccionado
                                    </div>
                                    <div class="col-12">
                                        {{booking.company.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row pb-3">
                            <div class="col-11 tx-headers" v-on:dblclick="setEditTextarea(3)">
                                <span>
                                    <ic-autobus icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[3]}}
                                </span>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nº de pasajeros salida
                                    </div>
                                    <div class="col-12">
                                        {{booking.passager_pickup}} pers.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Nº de pasajeros regreso
                                    </div>
                                    <div class="col-12">
                                        {{booking.passager_delivery}} pers.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12  txt-sub-headers">
                                        Tipo de traslado seleccionado
                                    </div>
                                    <div class="col-12">
                                        {{typeTansfer}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row pb-3">
                            <div class="col-11 tx-headers" v-on:dblclick="setEditTextarea(4)">
                                <span>
                                    <ic-escudo-candado icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[4]}}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Seguro seleccionado
                                    </div>
                                    <div class="col-12">
                                        {{insuranceName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row pb-3">
                            <div class="col-11 tx-headers" v-on:dblclick="setEditTextarea(5)">
                                <span>
                                    <ic-iidentificacion icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[5]}}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nombre
                                    </div>
                                    <div class="col-12">
                                        {{clientName}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Apellido
                                    </div>
                                    <div class="col-12">
                                        {{clientLastName}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        NIF/NIE
                                    </div>
                                    <div class="col-12">
                                        {{clientDNI}} &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Código postal
                                    </div>
                                    <div class="col-12">
                                        {{booking.client.postal_code}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Móvil
                                    </div>
                                    <div class="col-12">
                                        {{booking.client.phone}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Email
                                    </div>
                                    <div class="col-12">
                                        {{booking.client.email}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row">
                            <div class="col-11 tx-headers" v-on:dblclick="setEditTextarea(6)">
                                <span class="form-txt-info">
                                    <ic-documento icon-color="#fe3a00"/> 
                                    {{translate.confirmation[6]}}
                                </span>
                            </div>
                            <div class="col-1">
                                <span class="form-txt-info" @click="onShowInvoices" v-if="!showInvoice">
                                    <ic-flecha-pequena-abajo/>
                                </span>
                                <span class="form-txt-info" @click="onShowInvoices" v-if="showInvoice">
                                    <ic-flecha-pequena-arriba/>
                                </span>
                            </div>
                            <div class="col-12" v-if="!showInvoice">
                                <hr/>
                            </div>
                        </div>
                        <div class="row" v-if="showInvoice">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nombre
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        CIF 
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.cif}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Dirección
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.address}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Ciudad
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.city}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Código postal
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.postal_code}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row">
                            <div class="col-12 tx-headers" v-on:dblclick="setEditTextarea(7)">
                                <span>
                                    <ic-coche icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[7]}}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Marca
                                    </div>
                                    <div class="col-12">
                                        {{brandName}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Modelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.model}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Color
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.color}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Matrícula
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.licenseplate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3 pb-4">
                        <div class="row">
                            <div class="col-12 tx-headers" v-on:dblclick="setEditTextarea(8)">
                                <span>
                                    <ic-avion icon-color="#fe3a00" width="25" height="28"/>
                                    {{translate.confirmation[8]}}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Número de vuelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight.number}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Compañía
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight.company}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Ciudad/aerop. regreso
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight.city}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        País
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight.country}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr/>
                    </div>
                    <div class="col-12 text-center pt-1 ps-4 pe-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6 text-left tx-headers mt-2" v-on:dblclick="setEditTextarea(9)">
                                        {{translate.confirmation[9]}}
                                    </div>
                                    <div class="col-6 text-right txt-price">
                                        {{calculeTotal}}€
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center pt-2">
                        &nbsp;
                    </div>
                    <div class="col-12 ps-4 pe-4 pt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="booking.terms_parking_parking" 
                                id="terms_parking_parking">
                            <label class="form-check-label" for="terms_parking_parking" v-on:dblclick="setEditTextarea(10)">
                                {{translate.confirmation[10]}}
                            </label>
                        </div>
                    </div>
                    <div class="col-12 ps-4 pe-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="booking.terms_parking" 
                                id="terms_parking">
                            <label class="form-check-label" for="terms_parking" v-on:dblclick="setEditTextarea(11)">
                                {{translate.confirmation[11]}}
                            </label>
                        </div>
                    </div>
                    <div class="col-12 ps-4 pe-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="booking.terms_trasfer" 
                                id="terms_trasfer">
                            <label class="form-check-label" for="terms_trasfer" v-on:dblclick="setEditTextarea(12)">
                                {{translate.confirmation[12]}}
                            </label>
                        </div>
                    </div>
                    <div class="col-12 ps-4 pe-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="booking.terms_drivercar" 
                                id="terms_drivercar">
                            <label class="form-check-label" for="terms_drivercar" v-on:dblclick="setEditTextarea(13)">
                                {{translate.confirmation[13]}}
                            </label>
                        </div>
                    </div>
                    <div class="col-12 text-center pt-5 pb-5">
                        <div class="row">
                            <div class="offset-1 col-10 offset-md-4 col-md-4">
                                <ButtonOrange 
                                    type="button"
                                    v-if="!isEditing"
                                    :label="translate.confirmation[14]"
                                    @onClick="saveBooking"
                                />
                                <div v-else class="btn-booking" v-on:dblclick="setEditTextarea(14)">{{translate.confirmation[14]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea class="form-control p-2 mb-2" v-model="translate.confirmation[indexEdit]" rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import IcAvion from '@/components/Icons/IcAvion.vue'
import IcCoche from '@/components/Icons/IcCoche.vue'
import CardParking from '@/components/CardParking.vue'
import IcAutobus from '@/components/Icons/IcAutobus.vue'
import ButtonOrange from "@/components/ButtonOrange.vue"
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcCalendar from '@/components/Icons/IcCalendar.vue'
import IcDocumento from '@/components/Icons/IcDocumento.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import IcEscudoCandado from '@/components/Icons/IcEscudoCandado.vue'
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcIidentificacion from '@/components/Icons/IcIidentificacion.vue'
import IcFlechaPequenaAbajo from '@/components/Icons/IcFlechaPequenaAbajo.vue'
import IcFlechaPequenaArriba from '@/components/Icons/IcFlechaPequenaArriba.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'

export default {
    name: 'ConfirmationView',
    data() {
        return {
            areas: [],
            brands: [],
            services: [],
            insurances: [],
            indexEdit: null,
            showInvoice: false,
            showEditTexarea: false,
            booking: {
                end_date: null,
                end_hour: null,
                start_date: null,
                start_hour: null,
                end_minute: null,
                area_pickup_id: "",
                start_minute: null,
                type_vehicle_id: 0,
                terms_parking: true,
                terms_trasfer: true,
                area_delivery_id: "",
                terms_drivercar: true,
                code_promotion: null,
                terms_parking_parking: true,
                invoice: {
                    cif: null,
                    name: null,
                    city: null,
                    address: null,
                    postal_code: null,
                },
                client: {
                    id: 0,
                    email: "",
                    name: null,
                    phone: null,
                    last_name: null,
                    postal_code: null,
                    confirm_email: "",
                },
                vehicle: {
                    model: null,
                    color: null,
                    brand_id: "",
                    licenseplate: null,
                },
                flight: {
                    hour: null,
                    city: null,
                    number: null,
                    company: null,
                    country: null,
                },
                company: {
                    id: null,
                    logo: null,
                    name: null,
                    info: null,
                    price: null,
                    email: null,
                    domain: null,
                    defaul: null,
                    discount: null,
                    features: null,
                    rate_today: null,
                    description: null,
                    bill_method: null,
                    ignore_trips: null,
                    limit_extra_services: null
                }
            }
        }
    }, 
    components: {
        Footer,
        NavBar,
        IcAvion,
        IcCoche,
        SubMenu,
        SubFooter,
        IcAutobus,
        IcCalendar,
        IcEstrella,
        IcDocumento,
        CardParking,
        ButtonOrange,
        IcInformacion,
        IcEscudoCandado,
        IcIidentificacion,
        ResumeBookingHeader,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
        IcCirculoFlechaDerecha,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTexarea = false;
        },
        setEditTextarea(index) {
            const self = this;
            if (self.isEditing) { 
                self.indexEdit = index;
                self.showEditTexarea = true; 
            }
        },
        onShowInvoices() {
            this.showInvoice = !this.showInvoice;
        },
        saveBooking() {
            if (this.booking.terms_drivercar && this.booking.terms_parking && this.booking.terms_trasfer && this.booking.terms_parking_parking) {
                axios.post('/naranja/booking', this.booking)
                    .then(response => {
                        localStorage.clear();
                        this.$router.push(`/resume/${response.data.data.id}`);
                    }).catch((er) => {
                        console.log(er);
                    });
            } else {
                alert("Por favor acepte los terminos y condiciones");
            }
        },
        onMoreInfo() {
            this.$router.push('/parkings/information')
        },
        getAreas() {
            const self = this;
            axios.get(`/areas/naranja/${this.company.id}`)
                .then(response => {
                    self.areas = response.data.data;
                }).catch(() => {
                    store.dispatch('setCompany', null);
                });
        },
        getServices() {
            const self = this;
            axios.get(`/pakages/${this.company.id}`)
                .then(response => {
                    self.services = response.data.data;
                }).catch(() => {
                    console.error("Error")
                });
        },
        getInsurances() {
            const self = this;
            axios.get(`/insurances/${this.company.id}`)
                .then(response => {
                    self.insurances = response.data.data;
                }).catch(() => {
                    console.error("Error")
                });
        },
        getBrands() {
            const self = this;
            axios.get('/brands')
                .then(response => {
                    self.brands = response.data.data;
                }).catch(() => {
                    store.dispatch('setCompany', null);
                });
        }
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing']),
        clientDNI() {
            if (this.booking.client != null) {
                return  this.booking.client.dni_nie;
            } else {
                return  "";
            }
        },
        clientName() {
            if (this.booking.client != null) {
                return  this.booking.client.name;
            } else {
                return  "";
            }
        },
        clientLastName() {
            if (this.booking.client != null) {
                return  this.booking.client.last_name;
            } else {
                return  "";
            }
        },
        brandName() {
            if (this.booking.vehicle.brand_id > 0 && this.brands.length > 0) {
                var brand = this.brands.find(element => element.id == this.booking.vehicle.brand_id);
                return  brand.name;
            } else {
                return  "";
            }
        },
        insuranceName() {
            if (this.booking.insurance_id > 0 && this.insurances.length > 0) {
                var insurance = this.insurances.find(element => element.id == this.booking.insurance_id);
                return  insurance.title;
            } else {
                return  "";
            }
        },
        typeTansfer() {
            if (this.booking.service_id > 0 && this.services.length > 0) {
                var service = this.services.find(element => element.id == this.booking.service_id);
                return  service.title;
            } else {
                return  "";
            }
        },
        fechaLLegada() {
            return `${this.booking.start_date} ${this.booking.start_hour}:${this.booking.start_minute}`;
        },
        fechaSalida() {
            return `${this.booking.start_date} ${this.booking.start_hour}:${this.booking.start_minute}`;
        },
        terminalSalida() {
            if (this.booking.area_pickup_id > 0 && this.areas.length > 0) {
                var area = this.areas.find(element => element.id == this.booking.area_pickup_id);
                return  area.name;
            } else {
                return  "";
            }
        },
        terminalLLegada() {
            if (this.booking.area_delivery_id > 0 && this.areas.length > 0) {
                var area = this.areas.find(element => element.id == this.booking.area_delivery_id);
                return  area.name;
            } else {
                return  "";
            }
        },
        diasDiferencia() {
            if (this.booking.area_pickup_id) {
                return  "";
            } else {
                return  "";
            }
        },
        calculeTotal() {
            let total = 0;

            if (this.booking.insurance_id > 0 && this.insurances.length > 0) {
                var insurance = this.insurances.find(element => element.id == this.booking.insurance_id);
                total = insurance.price;
            }

            if (this.booking.service_id > 0 && this.services.length > 0) {
                let service = this.services.find(element => element.id == this.booking.service_id);
                if (service.discount > 0) {
                    var promoDiscount = (service.price*(service.discount/100)); 
                    var pricePromo = (service.price - promoDiscount);
                    total += (this.booking.passager_pickup  < 8 && this.booking.passager_pickup > 0)  < 8 ? (pricePromo * this.booking.passager_pickup) : pricePromo;
                    total += (this.booking.passager_delivery  < 8 && this.booking.passager_delivery > 0)  < 8 ? (pricePromo * this.booking.passager_delivery) : pricePromo;
                } else {
                    total += (this.booking.passager_pickup  < 8 && this.booking.passager_pickup > 0)  < 8 ? (service.price * this.booking.passager_pickup) : service.price;
                    total += (this.booking.passager_delivery  < 8 && this.booking.passager_delivery > 0)  < 8 ? (service.price * this.booking.passager_delivery) : service.price;
                }
            }

            return parseFloat(total).toFixed(2);
        },
    },
    mounted() {
        this.getAreas();
        this.getBrands();
        this.getServices();
        this.getInsurances();
        let booking = localStorage.getItem('booking');
        console.log(booking);
        this.booking = JSON.parse(booking);
        this.booking.terms_drivercar = true;
        this.booking.terms_parking = true;
        this.booking.terms_trasfer = true;
        this.booking.terms_parking_parking = true;
    }
}
</script>

<style scoped>
.btn-booking {
    width: 100%;
    padding: 8px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: #fe3a00;
}
.form-check-label {
    color: #000;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-price-total {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
}

.txt-price {
    font-family: 'Raleway';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #fe3a00;
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.txt-sub-headers {
    color: #c2c2c2;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.tx-headers {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
    text-transform: uppercase;
}

.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
