<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M12.0104089,8 L6,14.010406 L7.41421402,15.4246221 L12.0104079,10.8284273 L16.6066008,15.4246221 L18.0208149,14.0104089 L12.0104089,8 Z" id="arrow-/-chevron_up" fill-rule="nonzero" transform="translate(12.010407, 11.712311) scale(1, -1) translate(-12.010407, -11.712311) "></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_flecha_pequena_abajo'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
