<template>
    <div class="home">
        <NavBar/>
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu parkings="true" on-select="2"/>
        </div>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center pt-3">
                        <span class="header-text">
                            <span class="text-style-1">Reserva de parking</span> en el aeropuerto Madrid Barajas
                        </span>
                    </div>
                    <div class="col-12 text-center pt-1">
                        <span class="header-body">
                            Revise la información de su reserva y confirme los datos.
                        </span>
                    </div>
                    <div class="d-sm-none col-12 pt-3 ps-4 pe-4">
                        <ButtonOrange 
                            type="button"
                            label="Descargar"
                            @onClick="saveBooking"
                        />
                    </div>
                    <div class="d-sm-none col-12 pt-3 ps-4 pe-4">
                        <button 
                            :type="type" 
                            @click="onClick"
                            :disabled="!active"
                            class="button-transparent"
                        >
                            <span>
                                Compartir 
                                <ic-compartir/>
                            </span>
                        </button>
                    </div>
                    <div class="col-12 pt-4">
                        <div class="row">
                            <div class="col">
                                <span class="txt-section-title">
                                    <ic-parking icon-color="#fe3a00" width="25" height="28"/>
                                    Nº de reserva
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="card txt-number-booking">
                                    <div class="card-body text-center">
                                        {{booking.number_booking}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-3">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Localizador
                                    </div>
                                    <div class="col-12">
                                        {{booking.location}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-3">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Expediente operador
                                    </div>
                                    <div class="col-12">
                                        {{booking.proceedings}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4" v-for="(item, index) in booking.pakage.sections" :key="index">
                        <div class="row">
                            <div class="col">
                                <span class="txt-section-title">
                                    <img :src='item.icon' alt='Icono' style="margin:10px">
                                    {{item.title}}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 p-4 txt-section-body">
                                <span v-html="item.description"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pb-2">
                        <div class="row pb-3">
                            <div class="col">
                                <span class="txt-section-title">
                                    <ic-calendar icon-color="#fe3a00" width="25" height="28"/>
                                    Datos  de la reserva
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Fecha de entrada al parking
                                    </div>
                                    <div class="col-12">
                                        {{fechaLLegada}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Terminal de salida vuelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.area_pickup.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Fecha de salida del parking
                                    </div>
                                    <div class="col-12">
                                        {{booking.time_return}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Terminal de llegada vuelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.area_delivery.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nº total de días
                                    </div>
                                    <div class="col-12">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Parking seleccionado
                                    </div>
                                    <div class="col-12">
                                        {{booking.company.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row pb-2">
                            <div class="col-11 tx-headers">
                                <span class="txt-section-title">
                                    <ic-escudo-candado icon-color="#fe3a00" width="25" height="28"/>
                                    Seguros
                                </span>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-12 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Seguro seleccionado
                                    </div>
                                    <div class="col-12">
                                        {{booking.insurance.title}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row pb-3">
                            <div class="col-11 tx-headers">
                                <span class="txt-section-title">
                                    <ic-iidentificacion icon-color="#fe3a00" width="25" height="28"/>
                                    Datos del depositario
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nombre
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Apellido
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.last_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        NIF/NIE
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.dni}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Código postal
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.postal_code}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Móvil
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.phone}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Email
                                    </div>
                                    <div class="col-12">
                                        {{booking.user.email}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-5">
                        <div class="row">
                            <div class="col-10 tx-headers">
                                <span class="txt-section-title">
                                    <ic-documento icon-color="#fe3a00"/> 
                                    factura a nombre de la empresa
                                </span>
                            </div>
                            <div class="col-2">
                                <span class="form-txt-info" @click="onShowInvoices" v-if="!showInvoice">
                                    <ic-flecha-pequena-abajo/>
                                </span>
                                <span class="form-txt-info" @click="onShowInvoices" v-if="showInvoice">
                                    <ic-flecha-pequena-arriba/>
                                </span>
                            </div>
                            <div class="col-12" v-if="!showInvoice">
                                <hr/>
                            </div>
                        </div>
                        <div class="row"  v-if="showInvoice">
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Nombre
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Modelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.cif}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Dirección
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.address}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Ciudad
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.city}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 ps-4 pe-4 pt-4">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Código postal
                                    </div>
                                    <div class="col-12">
                                        {{booking.invoice.postal_code}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="row">
                            <div class="col-12 tx-headers">
                                <span class="txt-section-title">
                                    <ic-coche icon-color="#fe3a00" width="25" height="28"/>
                                    Datos del vehículo
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Marca
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.brand.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Modelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.model}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Color
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.color}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Matrícula
                                    </div>
                                    <div class="col-12">
                                        {{booking.vehicle.licenseplate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4">
                        <div class="row">
                            <div class="col-12 tx-headers">
                                <span class="txt-section-title">
                                    <ic-avion icon-color="#fe3a00" width="25" height="28"/>
                                    Datos del vuelo de regreso
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Número de vuelo
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight_id}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Compañía
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight_company}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        Ciudad/aerop. regreso
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight_city}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 ps-4 pe-4 pt-2">
                                <div class="row">
                                    <div class="col-12 txt-sub-headers">
                                        País
                                    </div>
                                    <div class="col-12">
                                        {{booking.flight_country}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4">
                        <div class="row">
                            <div class="col-11 tx-headers">
                                <span class="txt-section-title">
                                    <ic-documentos-check icon-color="#fe3a00" width="25" height="28"/>
                                    REsumen de servicios
                                </span>
                            </div>
                        </div>
                        <div class="row pt-1">
                            <div class="col-12 txt-section-subtitle-bold">
                                <div class="row ps-4 pe-4">
                                    <div class="col-8 pt-3">
                                        Plaza parking 2 dias
                                    </div>
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-12 txt-price text-right">
                                                {{booking.price_total}}€
                                            </div>
                                            <div class="col-12 txt-old-price text-right">
                                                {{booking.price_promo}}€
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row ps-4 pe-4">
                                    <div class="col pt-3 txt-section-subtitle">
                                        Descuento obtenido sobre tarifa
                                    </div>
                                    <div class="col-2 pt-3 text-right">
                                        <div class="row">
                                            <div class="col-12 txt-old-price">
                                                {{booking.pakage.discount}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr/>
                        </div>
                        <div class="row ps-4 pe-4">
                            <div class="col txt-section-subtitle-bold">
                                Seguro ampliado
                            </div>
                            <div class="col-4 text-right txt-price">
                                {{booking.insurance.price}}€
                            </div>
                        </div>
                        <div class="col-12">
                            <hr/>
                        </div>
                        <div class="row ps-4 pe-4">
                            <div class="col txt-section-subtitle">
                                Tasa de servicio (Internet)
                            </div>
                            <div class="col-4 text-right txt-price-normal">
                                0,00 €
                            </div>
                        </div>
                        <div class="col-12">
                            <hr/>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col txt-section-subtitle-bold pt-2" 
                                                style="text-transform:uppercase;">
                                                Importe total
                                            </div>
                                            <div class="col-4 text-right me-4 txt-price">
                                                {{booking.price_total+booking.insurance.price}}€
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 txt-sub-headers">
                                Forma de pago
                            </div>
                            <div class="col-12">
                                <span class="txt-section-subtitle-bold">
                                    Pago el día de regreso en el Parking o al Chofer en el terminal, <span style="font-weight: normal;">mediante tarjeta de credito, metalico o Bizum.</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-1 col-10 offset-md-4 col-md-4 p-2">
                    <ButtonOrange 
                        type="button"
                        label="Descargar"
                        @onClick="saveBooking"
                    />
                </div>
                <div class="offset-1 col-10 offset-md-4 col-md-4 p-2 pb-5">
                    <button 
                        :type="type" 
                        @click="onClick"
                        :disabled="!active"
                        class="button-transparent"
                    >
                        <span>
                            Compartir 
                            <ic-compartir/>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import IcAvion from '@/components/Icons/IcAvion.vue'
import IcCoche from '@/components/Icons/IcCoche.vue'
import CardParking from '@/components/CardParking.vue'
import IcParking from '@/components/Icons/IcParking.vue'
import ButtonOrange from "@/components/ButtonOrange.vue"
import IcCalendar from '@/components/Icons/IcCalendar.vue'
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcDocumento from '@/components/Icons/IcDocumento.vue'
import IcCompartir from '@/components/Icons/IcCompartir.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import IcEscudoCandado from '@/components/Icons/IcEscudoCandado.vue'
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcDocumentosCheck from '@/components/Icons/IcDocumentosCheck.vue'
import IcIidentificacion from '@/components/Icons/IcIidentificacion.vue'
import IcFlechaPequenaAbajo from '@/components/Icons/IcFlechaPequenaAbajo.vue'
import IcFlechaPequenaArriba from '@/components/Icons/IcFlechaPequenaArriba.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'

export default {
    name: 'ResumeView',
    data() {
        return {
            showInvoice: false,
            booking: {
                id: null,
                invoice: {
                    id: null,
                    cif: null,
                    name: null,
                    city: null,
                    address: null,
                    postal_code: null,
                },
                pakage: {
                    id: null,
                    price: null,
                    title: null,
                    default: null,
                    discount: null,
                    features: null,
                    description: null,
                    icons: [],
                    sections: [],
                },
                code: null,
                place: null,
                status: null,
                arrival: null,
                code_qr: null,
                price_total: null,
                price_promo: null,
                price_insurance: null,
                is_drive: null,
                location: null,
                proceedings: null,
                flight_hour: null,
                flight_city: null,
                number_booking: null,
                flight_company: null,
                flight_country: null,
                terms_parking: null,
                terms_trasfer: null,
                terms_drivercar: null,
                passager_pickup: null,
                type_vehicle_id: null,
                passager_delivery: null,
                terms_parking_parking: null,
                flight_id: null,
                kms_pickup: null,
                code_manual: null,
                time_return: null,
                kms_delivery: null,
                manual_pickup: null,
                agent: null,
                user:{
                    dni: null,
                    name: null,
                    email: null,
                    phone: null,
                    color: null,
                    avatar: null,
                    message: null,
                    favorite: null,
                    last_name: null,
                    postal_code: null,
                    favorite: null,
                    favorite: null,
                },
                time_reservation: null,
                parking: {
                    id: null,
                    info: null,
                    name: null,
                    plaza: null,
                },
                vehicle: {
                    id: null,
                    model: null,
                    color: null,
                    status: null,
                    licenseplate: null,
                    brand: {
                        id: null,
                        name: null,
                    },
                },
                area_pickup: {
                    id: null,
                    name: null,
                },
                area_delivery: {
                    id: null,
                    name: null,
                },
                insurance: {
                    id: null,
                    title: null,
                },
                company: {
                    id: null,
                    logo: null,
                    name: null,
                    info: null,
                    price: null,
                    email: null,
                    domain: null,
                    defaul: null,
                    discount: null,
                    features: null,
                    rate_today: null,
                    description: null,
                    bill_method: null,
                    ignore_trips: null,
                    limit_extra_services: null
                }
            },
        }
    }, 
    components: {
        Footer,
        NavBar,
        IcAvion,
        IcCoche,
        SubMenu,
        SubFooter,
        IcParking,
        IcEstrella,
        IcCalendar,
        IcDocumento,
        CardParking,
        IcCompartir,
        ButtonOrange,
        IcInformacion,
        IcEscudoCandado,
        IcDocumentosCheck,
        IcIidentificacion,
        ResumeBookingHeader,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
        IcCirculoFlechaDerecha,
    },
    methods: {
        onShowInvoices() {
            this.showInvoice = !this.showInvoice;
        },
        getBooking() {
            const self = this;
            axios.get(`/naranja/bookings/${this.$route.params.id}`)
                .then(response => {
                    self.booking = response.data.data;
                    console.log(JSON.stringify(self.booking));
                }).catch((ex) => {
                    console.error(ex)
                });
        },
    },
    computed: {
        ...mapState(['company']),
        fechaLLegada() {
            return `${this.booking.time_reservation}`;
        },
    },
    mounted() {
        this.getBooking();
    }
}
</script>

<style scoped> 
.txt-old-price {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-price {
    color: #fe3a00;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-price-normal {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-section-subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-section-subtitle-bold {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.button-transparent {
    width: 100%;
    padding: 8px;
    color: black;
    font-weight: bold;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: rgb(0,0,0, .2);
}

.txt-section-body {
    color: #000;
    font-weight: 500;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-section-title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
    text-transform: uppercase;
}

.txt-number-booking {
    color: #fe3a00;
    font-size: 24px;
    text-align: right;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.txt-sub-headers {
    color: #c2c2c2;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.card {
    border-radius: 20px;
    border: solid 1px #ededed;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
