<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor" d="M2,19.828558 L9.10793829,22.1978722 L16.0769157,18.2155991 L22,20.5848331 L22,4.43076634 L15.9230843,2 L8.89206171,6.01772738 L2,3.72037339 L2,19.828558 L2,19.828558 Z M14,17.0989046 L10,19.3846188 L10,7.6881218 L14,5.40240765 L14,17.0989046 L14,17.0989046 Z M16,16.0307665 L20,17.6307659 L20,5.78483224 L16.0769157,4.21559858 L16,4.25955033 L16,16.0307665 L16,16.0307665 Z M4,18.3870392 L8,19.7203732 L8,7.82855844 L4,6.49522495 L4,18.3870392 L4,18.3870392 Z" id="basic-/-map" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_mapa'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
