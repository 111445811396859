<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Oval" fill="#FFFFFF" cx="20" cy="20" r="20"></circle>
            <path :fill="iconColor" d="M10.5502529,17 L9.13603926,15.5857859 L12.6715727,12.0502529 L9.1360383,8.51471853 L10.5502529,7.10050488 L15.5,12.0502529 L10.5502529,17 Z M2,12 C2,6.47715187 6.47715378,2 12,2 C17.5228486,2 22,6.47715378 22,12 C22,17.5228486 17.5228467,22 12,22 C6.47715187,22 2,17.5228467 2,12 C2,8.31810125 2,8.31810125 2,12 Z M4,12 C4,7.58172131 7.58172321,4 12,4 C16.4182792,4 20,7.58172321 20,12 C20,16.4182792 16.4182773,20 12,20 C7.58172131,20 4,16.4182773 4,12 C4,9.05448087 4,9.05448087 4,12 Z" id="arrow-/-circle_chevron_right" ></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_circulo_flecha_derecha'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
