<template>
    <svg 
        version="1.1" 
        :width="width" 
        :height="height"  
        :aria-labelledby="iconName"
        xmlns="http://www.w3.org/2000/svg" 
        :viewBox="`0 0 ${width} ${height}`" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>{iconName}</title>
        <g :id="iconName" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path :fill="iconColor"  d="M20.5561236,4 L3.95965943,4 C2.92071295,4 2.07052158,4.75811136 2.00416859,5.71003985 L2,5.82999955 L2,18.5366674 C2,19.5037679 2.80863338,20.3005631 3.83081725,20.3627593 L3.95965943,20.366667 L20.5561236,20.366667 C21.5950706,20.366667 22.4452615,19.608556 22.5116145,18.6566272 L22.515783,18.5366674 L22.515783,5.82999955 C22.515783,4.86289912 21.7071499,4.06610393 20.6849658,4.00390768 L20.5561236,4 Z M3.95973842,5.84476086 L20.5562026,5.85617109 L20.6710222,5.97106966 L20.6710222,18.4070076 L20.5560446,18.5219061 L3.95958044,18.5104964 L3.84476083,18.3955978 L3.84476083,5.95965943 L3.95973842,5.84476086 Z M9.88770914,13.0761914 C8.09742464,13.0761914 6.35957224,14.6313447 6.16680636,16.072122 L6.15243528,16.2175508 L13.622376,16.2175508 L13.6218381,16.2027403 C13.5270467,14.8138314 11.9368592,13.2698011 10.2123053,13.0929564 L10.0502763,13.0804291 L9.88770914,13.0761914 Z M17.4439033,13.335128 L16.406752,13.335128 C15.8974059,13.335128 15.4844993,13.7480346 15.4844993,14.2573807 C15.4844993,14.7667268 15.8974059,15.1796334 16.406752,15.1796334 L17.4439033,15.1796334 C17.9532495,15.1796334 18.3661561,14.7667268 18.3661561,14.2573807 C18.3661561,13.7480346 17.9532495,13.335128 17.4439033,13.335128 Z M9.88773684,8.14911605 C8.75728541,8.14911605 7.92807741,8.97832405 7.92807741,10.1087755 C7.92807741,11.2396001 8.7566213,12.0684349 9.88773684,12.0684349 C11.0181883,12.0684349 11.8473963,11.2392269 11.8473963,10.1087755 C11.8473963,8.97832405 11.0181883,8.14911605 9.88773684,8.14911605 Z M17.4435204,9.18575665 L15.3699837,9.18575665 C14.860426,9.18575665 14.447348,9.59883474 14.447348,10.1083924 C14.447348,10.61795 14.860426,11.0310281 15.3699837,11.0310281 L17.4435204,11.0310281 C17.953078,11.0310281 18.3661561,10.61795 18.3661561,10.1083924 C18.3661561,9.59883474 17.953078,9.18575665 17.4435204,9.18575665 Z" id="user-/-id_card" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>

export default {
    props: {
        iconName: {
            type: String,
            default: 'ic_iidentificacion'
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
